$page_wrapper-bannerTop-desktop: 613px;
$page_wrapper-bannerTop-laptop: 613px;
$page_wrapper-bannerTop-tablet: 613px;
$page_wrapper-bannerTop-mobile: 448px;




.page.single.qualite {
	.banner.top {
		height:$page_wrapper-bannerTop-desktop;
	    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
	    @include brk($t) { 
	        height:$page_wrapper-bannerTop-tablet; 
	        margin-top: $header-height-tablet;
	    }
	    @include brk($m) { 
	        height:$page_wrapper-bannerTop-mobile; 
	        margin-top: $header-height-mobile;
	    }

	    .image {
	    	background-position: 50% 0%!important;
	    	@include brk($m) {opacity:0.10}
	    }


		.icon-garantie {
			width:100px;
			height: 100px;
			position: absolute;
			right:0;
			bottom:280px;
			animation: fade 2s;
			.st0{fill:none;stroke:#fff;stroke-width:0.2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			.st1{fill:#fff;}
			.st2{fill:#fff;}
			.st3{font-family:'Montserrat';font-weight:$bold;}
			.st4{font-size:1.9344px;}
			.st5{font-family:'Montserrat';font-weight:$light;}
			.st6{fill:none;stroke:#fff;stroke-width:0.173;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			.st7{fill:none;stroke:#fff;stroke-width:0.173;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			.st8{fill:none;stroke:#fff;stroke-width:0.173;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
			@include brk($m) {display:none}
		}
		.textes-container {
			animation: fade 2s;
			width:100%;
			position: absolute;
			bottom:0;
			left:0;
			padding: 30px 0 0px;
		}
		.bottom {
			width:100%;
			padding: 30px 0 62px;
			border-top:1px solid transparentize(#fff, 0.8);
			@include brk($t){
				padding: 30px 0 55px;
			}
		}
		.titre {
	        animation: fade 2s;
	        font-size:50px;
	        line-height: 1.1;
	        font-weight: $thin;
	        letter-spacing: 0.5px;
	        color:#fff;
	        position: relative;
	        z-index: 4;
	        margin-bottom: 165px;
	        @include brk($t) { margin-bottom: 45px; }
	        @include brk($m) { font-size: 25px; }
	    }
	    .sous-titre p {
	    	animation: fade 2s;
	    	color:#fff;
	    	font-size: 27px;
	    	font-weight: $light;
	    	@include brk($t) {
	    		font-size: 17.5px;
	    	}
	    	@include brk($m) {
	    		font-size: 17.5px;
	    	}
	    	span {
	    		color:$touche_de_couleur;
	    		font-weight: $bold;
	    	}
	    }
	}
}





.flex-qualites {
    background:#f9f9f9;
    padding-top:80px;
    padding-bottom:50px;
    // margin-bottom: 80px;
    @include brk($m) {padding-top:50px;}
    ul {display:inline-block;}
    li {
        width: 33.33%;
        display: inline-block;
        float: left;
        height:305px;
        padding-right:6%;
        @include brk($t) {width:50%;}
        @include brk($m) {width:100%;height:auto;padding-bottom:50px;}
    }
    .icon-container {
        height:90px;
        position: relative;
        margin-bottom: 35px;
        @include brk($m) {margin-bottom: 25px;}
        .image {
            background-size:contain;
            background-position:0% 50%;
        }
    }
    .texte-container {
        .titre {
            font-size: 17.5px;
            font-weight: $bold;
            margin-bottom: 8px;
        }
        .texte p {
            font-size: 15px;
            color:#6f95b9;
            font-weight: $light;
        }
    }
}


