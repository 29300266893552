





// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 393px;
$page_wrapper-bannerTop-laptop: 393px;
$page_wrapper-bannerTop-tablet: 393px;
$page_wrapper-bannerTop-mobile: 393px;

.page.single .banner.top {
    // margin-top: $header-height;
    // margin-top: -5px;
    // z-index: 100;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { 
        height:$page_wrapper-bannerTop-tablet; 
        // margin-top: $header-height-tablet;
    }
    @include brk($m) { 
        height:$page_wrapper-bannerTop-mobile; 
        // margin-top: $header-height-mobile;
    }
}
.page.single .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}


.page.single {
    background:#fff;
}


.page.single .banner.top {
    position: relative;
    background:#051637;
    .image {
        background-position: 50% 85%;
        @include brk($m) {
            filter: brightness(75%);
        }
    }
    .bloc {
        @include brk($t) {
            // padding: 50px 0 0 0;
        }
    }
    .sur-titre {
        margin-bottom: 8px;
        &, a {
            animation: fade 2.5s;
            font-size: 10.5px;
            color:#fff;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: $bold;
            position: relative;
            z-index: 4;
            &.dark {
                color:#104b99 !important;
            }
            @include brk($t) { 
                animation: fade_80 2.5s;
                opacity:0.8;
                color:#fff;
            }
        }
        a {
            padding: 10px 0;
        }
        a:hover {
            text-decoration: underline;
        };
        // @include brk($m) { font-size: 18px; }
    }
    .titre {
        animation: fade 2s;
        font-size:50px;
        line-height: 1.1;
        font-weight: $thin;
        letter-spacing: 0.5px;
        color:#fff;
        position: relative;
        z-index: 4;
        &.dark {
            color:#104b99 !important;
            font-weight: $light;
        }
        @include brk($td) { font-size: 45px; }
        @include brk($tp) { font-size: 40px; }
        @include brk($m) { font-size: 35px; }
    }
}



.banner.ariane {
    background: #ffffff;
    #nav_ariane {
        animation: fade 2s;
        padding: 30px 0 70px;
        >ul>li:not(.current-menu-parent)>a, 
        >ul>li:not(.current-menu-parent)>ul>li, 
        >ul>li:not(.current-menu-parent)>ul,
        >ul>li>ul>li:not(.current-menu-item)
        {
            display: none;
        }
        ul {
            display: inline-block;
        }
        >ul {
        }
        ul>li a {
            font-size: 10px;
            text-transform: uppercase;
        }
        >ul>li>a {
            color:#000000;
            &:after {
                content:"|";
                margin-left: 10px;
                display: inline-block;
            }
        }
        ul>li>ul>li>a {
            margin-left: 6px;
            color: $touche_de_couleur;
        }
    }
    p {
        display: none;
        animation: fade 2s;
        padding: 45px 0 40px;
        font-size: 10px;
        text-transform: uppercase;
        span.sep {
            margin: 0 8px;
        }
        span.child {
            color: $touche_de_couleur;
        }
    }
}




.page.single {
    .banner.medium {
        background: #ffffff;
        // padding-top:30px;
        // padding-bottom:80px;
    }
}



.flexible {
    li:first-child .flex-titre {
        margin-top: 0!important;
    }
    .flex {
        .titre {
            font-size: 27px;
            line-height: 1.5;
            font-weight: $light;
            color:$touche_de_couleur2;
            @include brk ($m) { font-size: 20px; }
        }
        .chapeau p {
            font-size: 14px;
            font-weight: $bold;
            color:$touche_de_couleur;
            letter-spacing: 0.2px;
            line-height: 1.8;
        }
    }
}












.page .flex {
    // .banner-content { padding:0; }

    &.flex-titre {
        margin-bottom: 0px;         
        margin-top: 70px;           
    }

    &.flex-wysiwyg {
        margin-bottom: 40px;
    }


    &.flex-image {
        height:337px;
        margin-bottom: 25px;
        @include brk ($t) { height:290px; }
        @include brk ($m) { height:118px; }
        &.has-lightbox .image {
            cursor:pointer;
        }
    }

    &.flex-intro {
        background:#f3f3f3;
        padding:55px 0 40px;
        // margin-bottom: 80px;
        @include brk ($m) { padding:45px 0 40px; }
        .titre {
            font-size: 27px;
            font-weight: $bold;
            color: $touche2;
            line-height: 1.5;
            @include brk ($t) { font-size: 24px; }
            @include brk ($m) { font-size: 22px; }
        }
        .texte {
            p, a, li {
                font-size: 23px;
                font-weight: $light;
                color: #1599d6;
                line-height: 1.5;
                @include brk ($t) { font-size: 21px; }
                @include brk ($m) { font-size: 18px; }
                b, strong {
                    font-weight: $bold;
                }
            }
        }
    }
    &.flex-accueil__solutions {
        >.banner {
            background:#fff!important;
        }
        .banner.solutions ul {
            display: inline-block !important;
            width:100%;
        }
    }

    &.flex-texte_image {
        // padding-top: 80px;
        margin-bottom: 80px;
        .col-texte {
            width:60%;
            animation: fade 1.5s;
            padding-right:6%;
            @include brk ($t) {margin-bottom:20px;width:50%;}
            @include brk ($m) {width:100%;padding-right:0%;}
            .chapeau {
                position: relative;
                &:before {
                    content:"";
                    display: inline-block;
                    height:2px;
                    width:75px;
                    position: absolute;
                    left:-100px;
                    top:18px;
                    background:$touche_de_couleur;
                    @include brk($l) {
                        width:40px;
                        left:-66px;
                    }
                    @include brk($t) {display:none;}
                }
            }
        }
        .col-image {
            width:39%;
            float: right;
            height:325px;
            @include brk ($t) {width:50%;}
            @include brk ($m) {height:235px; width:100%;}
            .image {
                border-radius:6px;
                animation: fade 2s;
                @include brk ($m) { 
                    border-radius:0px;
                    width:130%;
                    left:-15%;
                }
            }
        }
    }
    &.flex-image_texte {
        margin-bottom: 80px;
        .col-image {
            width:39%;
            height:325px;
            @include brk ($t) {margin-bottom:50px;width:46%;}
            @include brk ($m) {height:235px; width:100%;}
            .image {
                border-radius:6px;
                animation: fade 2s;
                @include brk ($m) { 
                    border-radius:0px;
                    width:130%;
                    left:-15%;
                }
            }
        }
        .col-texte {
            width:61%;
            padding-left: 6%;
            animation: fade 1.5s;
            @include brk ($t) {width:54%; margin-bottom:20px;}
            @include brk ($m) {width:100%;padding-left: 0%;}
        }
    }


    &.flex-focus {
        background:#fff;
        position: relative;
        padding: 25px 45px 0px;
        margin-bottom:40px;
        .titre {
            font-size: 17.5px;
            padding-bottom:5px;
            font-weight: $semi-bold;
        }
        &:before {
            content:"";
            display: inline-block;
            height:100%;
            width:6px;
            position: absolute;
            left:0; top:0;
            background:$touche_de_couleur;
        }
    }


    &.flex-accordeon {
        .ul-accordeon {
            // margin-bottom:120px;
        }
        .ul-accordeon>li {
            position: relative;
            display: inline-block;
            display: inline-block;
            width:100%;
            animation: fade 1s;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            .bloc-titre-container {
                cursor:pointer;
                padding-right: 45px;
                display: inline-block;
                width:100%;
                position: relative;
                .bloc-titre {
                    padding: 24px 0 26px;
                }
                &:hover {
                    .icon-container {
                        background:$touche_de_couleur;
                        // transform: scale(1);
                    }
                    .st0{stroke:#fff !important;}
                }
                .date {
                    cursor:pointer;
                    display: inline;
                    font-size: 14.5px;
                    font-weight: $bold;
                    color:$touche_de_couleur;
                    padding-bottom:0;
                    user-select:none;
                    margin-right: 10px;
                    // animation: fade 2s;
                }
                .titre {
                    cursor:pointer;
                    display: inline;
                    font-size: 14.5px;
                    // animation: fade 2s;
                    font-weight: $normal;
                    // user-select:none;
                    color:$touche_de_couleur;
                }
                .chapeau p {
                    font-size: 12px;
                    font-weight: $light;
                    color:#797979;
                    letter-spacing: 0.2px;
                    line-height: 1.8;
                    padding-bottom:0;
                    cursor: pointer;
                }
                .icon-container {
                    display: inline-block;
                    width:38px;
                    height:38px;
                    background:#fff;
                    border-radius:50%;
                    position: absolute;
                    right:0px;
                    top:17px;
                    text-align: center;
                    cursor: pointer;
                    // transform: scale(1.05);
                    transition: transform 0.3s;
                    .icon {
                        width:18px;
                        height:18px;
                        margin-top: 10px;
                        transition: transform 0.2s;
                    }
                    svg {
                        transition: transform 0.3s;
                        transform: scale(0.5);
                    }
                    .st0{fill:none;stroke:#82827f;stroke-width:8;stroke-miterlimit:10;}
                    .hor {transition: opacity 0.2s;}
                }
            }
            .bloc-contenu {
                padding-top: 10px;
                padding-bottom: 20px;
                opacity: 0;
                transition: opacity 0.5s;
                overflow: hidden;
                .image-container {
                    position: relative;
                    height:337px;
                    margin-bottom: 25px;
                    @include brk ($t) { height:290px; }
                    @include brk ($m) { height:118px; }
                    &.has-lightbox {cursor:pointer}
                }
                .col {
                    position: relative;
                    height:auto;
                    .image-col {
                        transform: translate3d(0,12px,0);
                        width:100%;
                        height:auto;
                        display: block;
                        &.has-lightbox {
                            + .overlay {
                                cursor:pointer;
                                // &:hover {transition: opacity 0.5s; opacity: 1;}
                            }
                        }
                    }
                    @include brk($m) {
                        width:100%!important;
                    }
                    .overlay {
                        @extend .abs;
                        opacity: 0;
                        transition: opacity 0.3s;
                        background:rgba(0,0,0,0.1);
                        .icon {
                            width: 34px;
                            height: 34px;
                            position: absolute;
                            bottom:0;
                            right:0;
                            // transform:rotate(270deg);
                            .st0 {fill:#fff}
                        }
                    }
                }
                .col1 {width:23.2%;}
                .col2 {float:right;width:71.8%;}
            }
            &.opened {
                .bloc-contenu {transition: opacity 1.5s; opacity: 1}
                .bloc-titre .icon-container {
                    background:$touche_de_couleur;
                    .st0{stroke:#fff !important;}
                }
                .bloc-titre:hover .icon-container {
                    background:#e71e4b;
                }
                .icon-container .icon {transform: rotate(180deg);}
                .icon-container .vert {opacity:0}
            }
        }
    }
}





.flex-focus_fond_bleu {
    padding: 65px 0 55px;
    background:#051637;
    margin-top: -5px;
    // margin-bottom: 80px;
    animation: fade 2s !important;
    @include brk($t) {
        padding: 65px 0 75px;
    }
    .content {
        position: relative;
    }
    .titres-container{
        width: 30%;
        float: left;
        padding-right:6%;
        margin-bottom: 20px;
        @include brk($t) {
            width:100%;
            clear: both;
        }
        .titre {
            color:#fff;
            font-size: 25px;
        }
        .chapeau p {
            color:#92b5e2;
            font-weight: $light;
            line-height: 2;
        }
    }
    .liste-container {
        width: 40%;
        float: left;
        @include brk($t) {
            width:100%;
            clear: both;
        }
        ul {
            margin-top: -13px;
            @include brk($t) {
                margin-top: -5px;
                margin-bottom: 10px;
            }
        }
        li{
            &:before {display:none}
            padding-left: 46px;
            &:last-child p {border:none!important}
            .icon {
                position: absolute;
                top:13px;
                left:8px;
                height:17px;
                width:17px;
                .st0{fill:#dd5953;stroke:#dd5953;stroke-width:7;stroke-linecap:square;}
            }
        }
        p {
            color:#fff;
            font-size: 16px;
            font-weight: $light;
            padding-bottom:16px;
            border-bottom:1px solid transparentize(#fff, 0.9);
        }
    }
    .btn-01 {
        background: #dd5953;
        border-radius:5px;
        position: absolute;
        right:0;
        @include brk($t) {
            position: relative;
            right:auto;
        }
        &:hover {
            background: darken(#dd5953, 15%);
        }
    }
}   





.flex-symptomes {
    text-align: center;
    background:#f8f8f8;
    padding:60px 0 67px;
    margin-bottom: 85px;
    .titre {
        padding-bottom: 17px;
    }
    .sous-titre {
        font-size: 14px;
        font-weight: $bold;
        color:$touche_de_couleur;
        margin-bottom: 55px;
    }
    ul {
        display: flex;
        flex-flow: wrap;
        justify-content: center;
    }
    li {
        width:16.66%;
        @include brk($t) {
            width:33.33%;
            height:280px;
        }
        @include brk($m) {
            width:100%;
            height: auto;
            margin-bottom: 50px;
        }
        .image-container {
            position: relative;
            height:160px;
            width:160px;
            margin: 0 auto 27px;
            .image {
                border-radius:50%;
                overflow:hidden;
            }
            @include brk($t) {
                margin-bottom: 23px;
            }
        }
        .texte-container {
            p {
                font-size: 14px;
                text-align: center!important;
                font-weight: $regular;
                padding: 0 20px;
            }
        }
    }
}










.flex-3_colonnes {
    padding-top:50px;
    .titres-container {
        .titre {
            font-size:27px;
            text-align:center;
            line-height:1.2;
            color:$touche2 !important;
            @include brk ($t) { font-size: 24px; }
            @include brk ($m) { font-size: 22px; }
        }
        margin-bottom:80px;
    }
    ul {
        display:inline-block;
        width: 100%;
        margin-bottom:50px;
        li {
            width: 33.33%;
            float:left;
            text-align:center;
            padding:0 4%;
            margin-bottom:40px;
            &:nth-child(3n + 1) {clear:both;}
            @include brk($tp) {
                width: 50%;
                clear:none !important;
                &:nth-child(2n + 1) {clear:both !important;}
            }
            @include brk($m) {
                width: 100%;
            }
            .image-container {
                height: 118px;
                width:118px;
                position: relative;
                display:inline-block;
                margin-bottom:23px;
            }
            .titre {
                color:#1599d6 !important;
                font-size:19px !important;
                font-weight:$bold !important;
                padding-bottom:5px;
            }
            .texte {
                p {
                    font-size:15px !important;
                    color:#838383;
                    line-height:1.55;
                }
            }
        }
    }
}















// ================================================================================
/*    SINGLE
==================================================================================*/


.modulePartage {
    height:54px;
    display: inline-block;
    .titreSection {
        color:$orange;
        font-weight: $bold;
        float:left;
        padding-top:20px;
        padding-right:80px;
    }
    .iconsContainer {
        height:54px;
        width:200px;
        float:left;
    }
    .icon {
        float:left;
        cursor: pointer;
        margin-right: 1px;
        height:54px;
        width:54px;
        background:#dadada;
        svg {transform:scale(0.8)}
        path {fill:#fff;}
    }
}



