$ft: $t + 0px;
$fm: $m + 0px;



footer {
    min-height: 200px;
    overflow-y: hidden;
    background: $footer-color;
    @include breakpoint ($tablet) {min-height: 600px;}
    @include breakpoint ($mobile) {min-height: 595px;}

    #footer_top {
        background:#051637;
        padding-top:50px;
        padding-bottom:25px;
        min-height: 220px;
        @extend .clearfix;
        @include brk($ft) {
            padding-bottom:25px;
        }
        @include brk($fm) {
            padding-top:30px;
            padding-bottom:30px;
        }
        .texte-container {
            padding-bottom:58px;
            @include brk($ft) {
                padding-bottom:35px;
            }
            @include brk($fm) {
                padding-bottom:35px;
            }
            p {
                font-size: 25px;
                font-weight: $light;
                &.bold {
                    font-weight: $bold;
                    color:$touche_de_couleur;
                }
                @include brk($fm) {
                    display: inline;
                    font-size: 16.3px;}
            }
        }
        .btn-01 {
            position: absolute;
            top:10px;
            right:0;
            display: inline-block;
            background:none!important;
            border:1px solid transparentize(#fff,0.8);
            border-radius:6px;
            height:63px!important;
            @include brk($ft) {
                top:0;
                right:auto;
                position: relative;
                margin-bottom: 70px;
            }
            @include brk($fm) {
                display: none;
            }
            &:hover, &:focus {
                background:darken($touche_de_couleur, 0%)!important;
                border:1px solid transparentize($touche_de_couleur,0);
            }
            p {
                padding-top:19px;
                color:#fff;
                cursor: pointer;
            }
            .icon {
                top:14px;
            }
        }

        p, a {
            color:$white;
            font-size: 15px;
        }
        .logo-container {
            width:200px;
            position: relative;
            @include brk($ft) {display:none}
            .logo {
                position: absolute;
                top:0;
                left:0;
                width:260px;
                // .st0{fill:#fff;fill:#fff;}
                // .st1{fill:#fff;fill:#fff;}
                // .st2{fill:#fff;fill:#cdd0d7;}
                // .st3{fill:#fff;fill:#cdd0d7;}
                .st0{fill:#FFFFFF;}
                .st1{opacity:0.8;fill:#FFFFFF;enable-background:new    ;}
                .st2{fill:#188DC4;}
                .st3{fill:#1999D6;}
                .st4{fill:none;stroke:#C0C2C4;stroke-width:0.5;stroke-miterlimit:10;}
                .texte {
                    display: none;
                }
            }
        }
        .adresses-container {
            float: right;
            width: calc(100% - 300px);
            padding-bottom:30px;
            @include brk($ft) {width:100%;padding-bottom:30px;}
            @include brk($tp) {padding-bottom:0px;}
            .col {
                width:33.33%;
                min-height:85px;
                @include brk($tp) {
                    margin-bottom: 30px;
                    width:50%;
                }
                @include brk($fm) {
                    min-height: auto;
                    height:auto;
                    margin-bottom: 25px;
                    width:100%;}
                .province {
                    color: $touche_de_couleur;
                    font-weight:$bold;
                    margin-bottom: 5px;
                    font-size: 13.5px;
                    @include brk($fm) {
                        margin-bottom: 5px;
                    }
                }
                p, a {
                    font-size: 13.5px;
                    font-weight: $light;
                    line-height: 1.85;
                }
                p {
                    color:#8392a5;
                }
                span {
                    color:#fff;
                    font-size: 13.5px;
                }
                a span {
                    font-weight: $bold;
                }
                .telephone {
                    margin-left: 5px;
                }
            }
        }
        .labels-container {
            clear: both;
            width:250px;
            margin-right: 50px;
            height:100px;
            float: left;
            display: none;
            @include brk($ft) {
                margin-right: 0px;
                width: 33.33%;
            }
            @include brk($fm) {width:100%;}
        }
        .appel-gratuit-container {
            float: left;
            width: calc(100% - 500px);
            margin-left: 300px;
            @include brk($ft) {
                width: 60%;
                margin-left: 0;
            }
            @include brk($tp) {width:100%;}
            a {
                padding-top:20px;
                padding-bottom:25px;
                font-size: 16px;
            }
            .appel-gratuit {
                color:$touche_de_couleur;
                font-weight: $bold;
            }
            .numero {
                font-weight: $bold;
            }
        }
        .social-container {
            float: right;
            margin-top: 12px;
            @include brk($tp) {float:left;}
            .icon-container {
                .icon {
                    width:40px;
                    height:40px;
                }
                .st0 {fill:#0d1221}
                .st1 {fill:$touche_de_couleur; transition: fill 0.3s}
                &:hover, &:focus {
                    .st0 {fill:darken(#0d1221, 25%)}
                    .st1 {fill:lighten(#fff, 20%);}
                }
            }
        }

    }
    #footer_bottom {
        background:#020b1b;
        padding-top: 9px;
        height:62px;
        @include brk($fm) {
            height:auto;
            padding-top: 17px;
            padding-bottom: 7px;
        }
        .banner-content {
            @include brk($tp) {
                padding: 0;
            }
        }
    }
}











footer #footer_bottom .copyright-line {
    display:inline-block;
    text-align:center;
    width:100%;
    .footer-copyright-menu {
        width: auto;
        display: inline-block;
        ul {
            width: auto;
            display: inline-block;
            li {
                display: inline-block;
                @include brk($fm) {
                    width: 100%;
                }
            }
        }
    }
    p, a, span {
        color:rgba(255,255,255,0.5);
        // text-transform: uppercase;
        font-size: 11px;
        letter-spacing:0.6px;
        transition: color 0.6s;
    }
    a {
        display: inline-block;
        padding: 15px 15px;
        text-decoration: none;
        position: relative;
        cursor:pointer;
        animation: fade 2s;
        @include brk($tp) {
            font-size: 10px;
            padding: 15px 10px;
        }
        @include brk($fm) {
            padding: 2px 10px;
            font-size: 10px;
        }
        &:hover, &:focus {color:#eee;}
        &:first-child {
            border-bottom:none;
            // &:before {display:none;}
        }
        &::after {
            content:"";
            display: inline-block;
            position: absolute;
            top:15px;
            right:-4px;
            width:1px;
            height:15px;
            background:rgba(255,255,255,0.15);
            @include brk($fm){display:none;}
        }
    }
    p {
        display:inline;
    }
}












footer #footer_bottom #signature_SFD {
    // float: right;
    // margin-top: 9px;
    padding-bottom: 8px;
    padding-left: 8px;
    position: relative;
    margin-left: 8px;
    @include brk($fm) {
        float: none;
        margin-top: 7px;
        padding-left: 0;
        margin-left: -5px;
    }
    &:hover, &:focus {
        p {color:rgba(255,255,255,0.9)!important}
        .icon path {fill:#eee}
    }
    p {
        font-size: 11px;
        display: inline-block;
        color:rgba(255,255,255,0.5);
        margin-right: 7px;
        // margin-top: 13px;
        transition: color 0.5s;
        cursor: pointer;
        @include brk($tp) {
            font-size: 10px;
        }
    }
    .icon {
        position: absolute;
        top:6px;
        right:-13px;
        height:25px;
        width:25px;
        margin-top: 6px;
        path {
            fill:#9b9b9b;
            transition: fill 0.5s;
        }
        @include brk($fm){
            top:-7px;
        }
    }
}

