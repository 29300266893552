
/* NAV TABLET
====================*/



// COLORS
$container-background: $touche_de_couleur2;


// LEVEL 1
$bg-l1-top: #051637;
$bg-l1: $touche_de_couleur2;
$bg-l1-robe: #121c33;
$level1-hover-background: darken($touche_de_couleur2, 8%);

// LEVEL 2
$bg-l2: #051637;
$bg-l2-top: $touche_de_couleur;
$bg-l2-robe: darken($touche_de_couleur2, 10%);
$level2-hover-background: $touche_de_couleur2;

// LEVEL 3
$bg-l3-top: $dark-red;
$level3-background: $level3;
$level3-hover-background: $level3;






.btn-home {
    z-index: 1012;
    position: absolute;
    display: inline-block;
    top:0;
    left:0;
    height:70px;
    width:70px;
    background:$white;
    animation: fade 1.5s;
    svg {transform:scale(0.4)}
    path {
        fill: #000;
        fill-rule: evenodd;
     }
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
}






// CONTAINER ------------------------------------

@keyframes open_menu {
    0% { transform:translate3d(100%, 0px, 0px); }
    100% { transform:translate3d(0, 0px, 0px); }
}


#nav_mobile_container {
    transform:translate3d(0px, 0px, 0px);
    transition: transform 0.3s, opacity 0.3s;
    z-index: 990;
    height:100%;
    top:0px;
    right:0;
    overflow: hidden;
    background:$container-background;
    position:fixed;
    display: none;
    user-select:none;
    &.opened {
        display: block;
        animation: open_menu 0.3s;
    }
    @include brk ($t) {width:320px;}
    @include brk ($m) {max-width:calc(100% - 0px);}
}









#nav_mobile {
    height:100%;
    overflow-y:scroll;
    margin-right:-22px;
    background:$level1-hover-background;
    >ul {
        margin-top:$header-height;
        // background:$bg-l1;
        @include brk($t) {margin-top:$header-height-tablet;}
        @include brk($m) {margin-top:$header-height-mobile;}
    }
    >ul li:first-child {
        padding-top:5px; 
        a {border:none!important;}
    }
    >ul li a {
        line-height: 1.8;
        font-size:12.5px;
        position:relative;
        padding: 20px 0 20px 25px;
        letter-spacing: 1px;
        animation: fade 1.5s;
        // font-weight: $bold;
        // text-transform: uppercase;
        border-top:1px solid rgba(255,255,255,0.2);
        transition: border 0.6s;
    }
    >ul>li {
        // animation: fade 0.6s;
        background:$bg-l1;
    }
    >ul li {
        &:hover, &.current-menu-item, &.current-menu-ancestor {
            background:$level1-hover-background;
            a {color:$white;}
            >a, + li>a {border-top:1px solid transparentize(#000,1);}
        }
    }
    >ul>li>a {
        display: inline-block;
        position: relative;
        width:100%;
        color:$white;
    }
    >ul>li.menu-item-has-children>a::after {
            content:"›";
            position: absolute;
            font-size:28px;
            font-weight: $normal;
            height:100%;
            box-sizing: inherit;
            width:70px; 
            top:0px;
            right:15px;
            padding-left:25px;
            padding-top: 4px;
            text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
            // background:#edecec;
            @include brk($m) {}
        }
}



// LEVEL 2 --------------------------------------------


#nav_mobile >ul>li {
    ul {
        z-index: 1012;
        top:$header-height;
        @include brk($tablet) {top:$header-height-tablet;}
        @include brk($mobile) {top:$header-height-mobile;}
        li>a {
            letter-spacing: 1px;
            color:#eee;
            display: block;
            opacity: 0.1;
            transition: opacity 0.35s;
        }
        &.opened {
            transform: translate3d(7.4%, 0px, 0px);
            >li>a {
                opacity: 1;
            }
        }
    }
    >ul>li {
        background:$bg-l2;
    }
    >ul {
        background:$bg-l2-robe;
        overflow-y: scroll;
        overflow-x: hidden;
        height:100%;
        width: 108.1%;
        position:absolute;
        right:1px;
        transform:translate3d(108%, 0px, 0px);
        transition: transform 0.3s;
    }
    >ul>li {
        &.current-menu-item, &:hover {
            background:darken($bg-l2, 6%);
            >a, + li>a {border-top:1px solid transparentize(#fff,1);}
        }
    }
    >ul>li.menu-item-has-children>a::after {
        content:"›";
        position: absolute;
        font-size:28px;
        font-weight: $normal;
        height:100%;
        box-sizing: inherit;
        color:#fff;
        width:62px; 
        top:0px;
        right:0px;
        padding-left:25px;
        padding-top: 9px;
        // background:#edecec;
        // text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.15);
        @include brk($m) {}
    }
}




// LEVEL 3

#nav_mobile >ul>li>ul>li{
    ul{
        transform:translate3d(0px, 0px, 0px);
        transition: transform 0.3s;
        background:$level3-background;
        display:none;
        position:fixed;
        height:100%;
        a {
            color:#333333;
            font-weight: 700;
            &:hover {
                background:$level3-hover-background;;
            }
        }
    }
}





.calling-icon {
    height:$header-height-mobile;
    width:$header-height-mobile;
    background: #14c2ab;
    position:fixed;
    right:$header-height-mobile;
    top:0;
    z-index: 100;
    animation: fade 1s;
    transition: transform 0.6s;
    &.show-promo {
        transform: translateY(68px);
        @include brk($m) {
            transform: translateY(68px);
        }
    }
    &:hover {
        background:darken(#14c2ab, 5%);
    }
    svg {
        width:22px;
        height:22px;
        top:20px;
        margin-top: 22px;
        margin-left: 23px;
        .st0 {
            fill:#fff;
        }
    }
}



@import "hamburger/00_hamburger";
#hamburger_container {
    position: fixed;
    background: #051637;
    animation: fade 1s;
    width:$header-height; 
    height:$header-height;
    z-index: 1010;
    right:0;
    top:0;
    margin-right: - ($header-height + 50px);
    transition: transform 0.6s;
    &.show-promo {
        transform: translateY(68px);
        @include brk($m) {
            transform: translateY(68px);
        }
        &.is-active {
            transition: transform 1s ease 0s;
            transform: translateY(0);
        }
    }
    &.active {
        // animation:none!important
        background: $touche_de_couleur;
    }
    @include brk($t) {
        display:inline-block;
        margin-right: 0;
        width:$header-height-tablet; 
        height:$header-height-tablet;
    }
    @include brk($m) {
        width:$header-height-mobile; 
        height:$header-height-mobile;
    }
    // button {
    //     &:before {
    //         content:"";
    //         @extend .abs;
    //         background:url('../assets/images/sandwish-gradient.png');
    //         height:80%;
    //         background-size:cover;
    //         background-position:0% 0%;
    //         opacity: 1;
    //     }
    // }
}






// TOP LINE NAMES

.bar-top {
    display: block;
    height: $header-height;
    text-transform: uppercase;
    padding: 27px 0 0 25px;
    font-size:11px;
    color:$white;
    border-bottom: none;
    position:absolute;
    transform:translate3d(0px, 0px, 0px);
    transition: transform 0.3s;
    top:0px;
    right:0px;
    width:100%;
    letter-spacing: 1.8px;
    &::after {content:none;}
    &.l1 { background:$bg-l1-top;z-index: 1011; }
    &.l2 { background:$bg-l2-top;z-index: 1013; }
    &.l3 { background:$bg-l3-top;z-index: 1015; }
    &.l2, &.l3 {
        transform: translate3d(100%, 0px, 0px);
        letter-spacing: 1.8px;
        &.opened { transform: translate3d(0px, 0px, 0px) }
    }
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
}







.diagnostic.mobile {
    height: 68px;
    display: inline-block;
    background:#1599d6;
    width:100%;
    padding-top:15px;
    animation: fade 1s;
    position: relative;
    &:hover {
        background:darken($touche_de_couleur2, 10%);
        .icon-arrow span {transform:translate3d(1px,0,0);}
    }
    p {
        padding-left:80px;
        color:#fff;
        line-height: 1.6;
        font-size: 13px;
        letter-spacing: 0.5px;
        font-weight: $bold;
    }
    .icon {
        width:42px;
        height:42px;
        position: absolute;
        top:14px;
        left:24px;
        .st0{clip-path:url(#XMLID_14_);fill:#FFFFFF;}
        .st1{clip-path:url(#XMLID_14_);fill:#40A2C2;}
    }
}
