
$headroom_background: #051637;
$headroom_background_fade_out: 1s;
$headroom_background_fade_in: 0.6s;
$headroom_background_opacity: 0.96;

$headroom_speed_in: 0.6s;
$headroom_speed_out: 0.8s;


@keyframes slideUp {
    0% { transform: translateY(0); }
    100% { transform: translateY(-97px); }
}

@keyframes slideUp_showPromo {
    0% { transform: translateY(0); }
    100% { transform: translateY(-97px); }
}

@keyframes slideDown {
    0% { transform: translateY(-97px); }
    100% { transform: translateY(0); }
}

@keyframes slideDown_showPromo {
    0% { transform: translateY(-97px); }
    100% { transform: translateY(0); }
}




header#header.headroom {
    will-change: transform;
    animation-duration: $headroom_speed_in;
    animation-fill-mode: both;
    // animation-timing-function: ease-in-out;
    .background {
        transition: opacity $headroom_background_fade_in, height 0.6s;
        opacity: $headroom_background_opacity;
    }
    .background:not(.page) {
        background: $headroom_background;
    }
    &.page .background {
        // background: none !important;
    }
    &.headroom--top:not(.page) {
        .background {
            opacity: 0!important;
            transition: opacity $headroom_background_fade_in;
        }
    }
    &.headroom--pinned {
        animation-name: slideDown;
        &.show-promo {
            animation-name: slideDown_showPromo;

            .diagnostic-tablet.tablet-on {
                transform: translateY(0);

            }
        }
        .background {
            transition: opacity $headroom_background_fade_in;
            opacity: $headroom_background_opacity;
        }
    }
    &.headroom--unpinned {
        animation-duration: $headroom_speed_out;
        animation-name: slideUp;
        &.show-promo {
            animation-name: slideUp_showPromo;
        }
        .background {
            transition: opacity $headroom_background_fade_out;
            opacity: $headroom_background_opacity;
        }
        .hidden-btn-container .diagnostic {
            transition: transform 0.8s;
            transform: translate3d(0,81px,0);
            box-shadow: 0px 3px 25px 0px rgba(39, 51, 66, 0.46);
            @include brk($t) {
                transform: translate3d(0,100px,0);
            }
        }
    }
}



#hamburger_container.headroom, .calling-icon.headroom {
    will-change: transform;
    animation-duration: $headroom_speed_in;
    animation-fill-mode: both;
    // animation-timing-function: ease-in-out;
    &.headroom--top {
    }
    &.headroom--pinned {
        animation-name: slideDown;
        @include brk($t) {
            animation-name: none;
        }
    }
    &.headroom--unpinned {
        animation-duration: $headroom_speed_out;
        animation-name: slideUp;
        @include brk($t) {
            animation-name: none;
        }
    }
}

