
.front-page .banner.top {
    height:1059px;
    background:#0a1d44;
    position: relative;
    @include brk($t) { 
        height:auto; 
    }
    >.image {
        // background: url('../assets/images/home_top_3000x1900.jpg');
        background-size:cover;
        background-position:50% 40%;
        animation: fade 1s;
    }

    .content {
        overflow: visible;
    }

    .slider {
        position: absolute;
        top:123px;
        right:-214px;
        width:500px;
        height:800px;
        animation: fade 2s;
        @include brk($t) {
            width:650px;
            height:960px;
        }
        @include brk($m) {
            top:90px;
            width:560px;
            height:800px;
            right:-255px;
        }
        ul, li {
            height:100%;
            width: 100%;
        }
        li {
            position: relative;
            .image {
                background-size:contain;
            }
            .texte-container {
                position: absolute;
                top:100px;
                width:150px;
                left:20px;
                text-align: right;
                @include brk($t) {
                    display: none;
                }
                .nom, .fonction {
                    color:#fff;
                    text-transform: uppercase;
                    font-size: 11px;
                }
                .nom {
                    font-weight: $bold;
                }
                .fonction {
                    font-weight: $light;
                }
            }
        }
    }




    .bloc-container {
        margin-top: $header-height;
        height:450px;
        line-height: 450px;
        text-align:center;
        @include brk($t) { 
            height:auto;
            margin-top: 100px; 
            line-height: 1;
        }
        @include brk($m) { 
            margin-top: 60px; }
        .bloc {
            padding-top:110px;
            animation: fade 1s;
        }
        .titre {
            z-index: 80;
            position: relative;
            color:#fff;
            font-size: 62.5px;
            font-weight: $semi-bold;
            line-height: 1.2;
            margin-bottom: 17px;
            animation: fade 1s;
            br {display:none}
            @include brk($t) {
                font-size: 57.5px;
                line-height: 1.1;
                br {display:inline}
            }
            @include brk($tp) {
                font-size: 40px;
            }
        }
        .sous-titre p {
            position: relative;
            z-index: 80;
            color:#fff;
            font-size: 19.5px;
            font-weight: $light;
            animation: fade 2s;
            @include brk($tp) {
                font-size: 18.5px;
            }
        }
        form {
            z-index: 80;
            height:82px;
            width:100%;
            display: block;
            margin: 55px auto 0;
            max-width:824px;
            border-radius:4px;
            // overflow: hidden;
            animation: fade 1.5s;
            position: relative;
            box-shadow: 0px 3px 38px 0px rgba(39, 51, 66, 0.46);
            @include brk($td) {
                max-width: none;
            }
            @include brk($tp) {
                height:auto;
            }
            // &:before {
            //     content:"";
            //     display: inline-block;
            //     width:24px;
            //     height:18px;
            //     background: url('../assets/images/icon-envelop.svg');
            //     background-position:50% 50%;
            //     background-size:contain;
            //     position: absolute;
            //     left:35px;
            //     top:33px;
            //     z-index: 100;
            //     @include brk($tp) {display:none}
            // }
            input[type="text"] {
                float: left;
                height:100%;
                width:60%;
                background: #e9f1f6;
                padding-left:44px;
                padding-top: 2px;
                position: relative;
                border-top-left-radius:6px;
                border-bottom-left-radius:6px;
                // transition: background 0.5s !important;
                @include brk($t) {
                    width: 58%;
                }
                @include brk($tp) {
                    border-radius:6px;
                    height:82px;
                    width:100%;
                    margin-bottom: 6px;
                    padding-left:0px;
                    text-align: center!important;
                }
                &:focus, &.focus {
                    background: #fff;
                }
            }
            button[type="submit"] {
                float: left;
                height:100%;
                width:40%;
                background:#dd5953;
                color:#fff;
                border-top-right-radius:6px;
                border-bottom-right-radius:6px;
                &:hover, &:focus {
                    background:darken(#dd5953, 15%);
                }
                p {
                    padding-top:27px;
                }
                .icon {
                    top:20px;
                    padding-top:6px;
                    height:45px;
                    width: 45px;
                }
                @include brk($t) {
                    width: 42%;
                }
                @include brk($tp) {
                    border-radius:6px;
                    height:82px;
                    width:100%;
                    p {
                        padding-right:30px;
                        margin-left: -35px;
                    }
                }
            }
            .tooltip {
                position: absolute;
                top:-44px;
                left:45px !important;
                @include brk($tp){ 
                    left:calc(50% - 117px) !important;
                }
            }
            
            /**************  PLACEHOLDER ADJUST   ***************/


            input::placeholder {
                font-size: 16px;
                font-weight: $normal;
                color: transparentize(#000, 0.5);
                text-align: left;
                transition: color 0.3s;
                @include brk($t){ font-size:16px; }
                @include brk($tp){ font-size:16px; text-align: center;}
            }
            input:focus, textarea:focus {
                &::placeholder { 
                    color: transparentize(#000, 0.8);
                }
            }

        }
    }
    .points-forts {

        margin-top: 120px;
        @include brk($t) { 
            display: inline-block;
            margin-top: 65px; 
        }
        @include brk($m) {
            margin-top: 40px; 
            margin-bottom: 20px; 
        }
        ul {}
        li {
            width:31%;
            margin-right: 3.5%;
            float: left;
            background:#fff;
            text-align: center;
            height:345px;
            position: relative;
            border-radius: 12px;
            transition: transform 1s;
            animation: fade 3s;
            z-index: 80;
            a {
                position: relative;
                display: inline-block;
                z-index: 80;
                padding: 80px 40px 0;
            }
            &:hover {
                transition: transform 0.5s;
                transform:translate3d(0px, -10px, 0);
            }
            &:last-child {margin-right:0}
            .icon-container {
                position: absolute;
                top:-50px;
                left:0;
                width:100%;
                height:110px;
                @include brk($t) {
                    top:0;
                    width:100px;
                }
                @include brk($m) {
                    display: none;
                }
                .icon {
                    position: relative;
                    width:90px;
                    height:100px;
                    &.icon1 {
                        width:208px;
                        .st0{fill:#FFFFFF;}
                        .st1{fill:none;stroke:#DD5953;stroke-width:0.20;stroke-miterlimit:10;}
                        .st2{fill:none;stroke:#DD5953;stroke-width:0.2;stroke-miterlimit:10;}
                        .st3{fill:#DD5953;stroke:#DD5953;stroke-width:0.2;stroke-miterlimit:10;}
                        .st4{fill:#DD5953;}
                        @include brk($t) {
                            margin-top: -35px;
                            width:90px;
                            height:208px;
                            .st0{fill:#FFFFFF;}
                            .st1{fill:none;stroke:#DD5953;stroke-width:0.05;stroke-miterlimit:10;}
                            .st2{fill:#DD5953;stroke:none;}
                            .st3{fill:none;stroke:#DD5953;stroke-width:0.05;stroke-miterlimit:10;}
                            .st4{fill:#DD5953;stroke:#DD5953;stroke-width:0.1;stroke-miterlimit:10;}
                        }
                    }
                    &.icon2 {
                        .st0{fill:#1599D6;}
                        .st1{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
                        .st2{fill:#FFFFFF;}
                    }
                    &.icon3 {
                        .st0{fill:#1599D6;}
                        .st1{fill:none;stroke:#FFFFFF;stroke-width:2.0455;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
                    }
                }
            }
            .titre {
                font-size: 21.5px;
                font-weight: $bold;
                color:#104b99;
                margin-bottom: 25px;
                line-height: 1.55;
            }
            .texte p, .texte .col {
                font-size: 15px;
                color:#7b7b7b;
                line-height: 1.9;
                font-weight: $light;
                @include brk($t) {
                    color:#fff;
                }
            }
            .col {
                padding: 0 17px;
                &.col1 {
                    position: relative;
                    &:after {
                        content:"";
                        display: inline-block;
                        width:1px;
                        height:57px;
                        position: absolute;
                        right:5px;
                        top:14px;
                        background:transparentize(#7b7b7b,0.85);
                        @include brk($m) {
                            display:none;
                        }
                    }
                }
            }
            @include brk($t) {
                width:100%;
                margin-right: 0;
                height:166px;
                text-align: left;
                background: none;
                &:hover {transform:translate3d(0,0,0)!important}
                a {
                    padding: 0px 40px 0 120px;
                    position: relative;
                    &:after {
                        content:"";
                        width:calc(100%);
                        display: inline-block;
                        height:1px;
                        background: transparentize(#fff,0.9);
                        margin-top: 50px;
                    }
                }
                &:first-child {margin-bottom:20px;}
                &:last-child a:after {display:none;}

                .titre, .texte, .texte .col {
                    color:#fff;
                    br {display:none;}
                }
                .titre {
                    padding-top:10px;
                    margin-bottom: 10px;
                }
                .col {width:100%; padding: 0px;}
            }
            @include brk($m) {
                height:auto;
                text-align: center;
                &:first-child {margin-bottom:0px;}
                a {
                    padding: 0px 0px 20px 0px;
                    &:after {
                        content:"";
                        width:calc(100%);
                        display: inline-block;
                        height:1px;
                        background: transparentize(#fff,0.9);
                        margin-top: 30px;
                        // display: none;
                    }
                }
                .titre {
                    font-size: 16.5px;
                }
            }

        }
    }
    .gradient {
        position: absolute;
        width:100%;
        left:0;
        right:0;
        bottom:0;
        height:60px;
        z-index: 80;
        background: linear-gradient(to bottom, 
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0.07) 50%,
            rgba(0,0,0,0.2) 100%
            );
    }
    .gradient-blue {
        position: absolute;
        width:100%;
        left:0;
        right:0;
        bottom:0;
        height:600px;
        background: linear-gradient(to bottom, 
            transparentize(#081640,1) 0%, 
            transparentize(#081640,0.3) 50%,
            #081640 100%
        );
        @include brk($m) {
            height:500px;
        }
    }
}














.banner.etapes {
    .banner-content .content {
        >.sur-titre {
            font-size: 11px;
            color:#1599d6;
            text-transform: uppercase;
            letter-spacing: 4px;
            margin-bottom: 5px;
            @include brk($m) { 
                margin-bottom: 10px;
            }
        }
        >.titre {
            font-size: 35px;
            color:#104b99;
            font-weight: $bold;
            position: relative;
            margin-bottom: 60px;
            &:before {
                content:"";
                width:57px;
                height:1px;
                display: inline-block;
                position: absolute;
                left:-90px;
                top:28px;
                background:#1599d6;
                @include brk($l) {
                    display: none;
                }
            }
            @include brk($t) {
                margin-bottom: 40px;
            }
            @include brk($m) { 
                font-size: 27.5px;
                line-height: 1.2;
            }
        }
        @include brk($t) {
            text-align: center;
        }
    }
    
}








.banner.etapes {
    padding-top:310px;
    z-index: -1;
    margin-top: -5px;
    height:803px;
    background:#fff;
    @include brk($t) {
        height:auto;
        padding:75px 0 50px;
    }
    @include brk($m) {
        display: none;
    }
    ul {
        width:112%;
        margin-left: -6%;
        position: relative;
        display: inline-block;
        &:before {
            content:"";
            position: absolute;
            top:46px;
            bottom:0;
            height:50px;
            width:100%;
            left:0px;
            opacity: 1;
            background: url('../assets/images/ombre-etapes.png');
            background-size:contain;
            background-position:50% 50%;
        }
        @include brk($t) {
            width:100%;
            margin-left: 0%;
            &:before {
                top:-200px;
                z-index: 90;
                width: 160%;
                opacity: 0.8;
                margin-left: -30%;
                background-size:contain;
            }
        }
        @include brk($m) {
            &:before {
                display: none;
            }
        }
    }
    li {
        width:20%;
        display: inline-block;
        float: left;
        @include brk($t) {
            width:100%;
        }
        .icons-container {
            position: relative;
            height:150px;
            width: 90px;
            margin: 0 auto;
            .small-icon {
                position: absolute;
                top:0;
                right:-18px;
                font-size: 14px;
                font-weight: $bold;
                width: 47px;
                height: 47px;
                background: #dcf0f9;
                border-radius: 50%;
                text-align:center;
                padding-top:14px;
            }
            .big-icon {
                position: absolute;
                top:21px;
                left: 0;
                height:90px;
                width:90px;
            }
            @include brk($t) {
                margin:0;
                float: left;
                height:100px;
                width: 65px;
                .big-icon {
                    height:65px;
                    width:65px;
                    top:16px;
                }
                .small-icon {
                    height:35px;
                    width:35px;   
                    padding-top:9px;    
                }
            }
        }
        .texte-container {
            .texte {
                font-size: 17px;
                font-weight: $semi-bold;
                text-align: center;
                color: #0e4285;
                padding: 0 18px;
            }
            @include brk($t) {
                width: calc(100% - 120px);
                float: right;
                .texte {
                    text-align: left;
                    padding:34px 0 34px;
                    br {display:none;}
                    border-bottom: 1px solid transparentize(#000, 0.9);
                }
            }
        }
        &:last-child .texte {
            border:none!important
        }
    }
}


