




.banner.solutions {
    .banner-content .content .titres-container {
        >.sur-titre {
            font-size: 11px;
            color:#1599d6;
            text-transform: uppercase;
            letter-spacing: 4px;
            margin-bottom: 5px;
            @include brk($m) { 
                margin-bottom: 10px;
            }
        }
        >.titre {
            font-size: 35px;
            color:#104b99;
            font-weight: $bold;
            position: relative;
            margin-bottom: 60px;
            &:before {
                content:"";
                width:57px;
                height:1px;
                display: inline-block;
                position: absolute;
                left:-90px;
                top:28px;
                background:#1599d6;
                @include brk($l) {display:none;}
            }
            @include brk($t) { 
                margin-bottom: 33px;
            }
            @include brk($m) { 
                font-size: 27.5px;
                line-height: 1.2;
                &:before {display:none;}
            }
        }
        @include brk($t) { 
            text-align: center;
        }
    }
}


.page .banner.solutions {
    background:#ededed!important;
    .background {display:none;}
    .titres-container {
        text-align:left!important;
    }
}

.banner.solutions {
    padding-top:95px;
    margin-top: 0px;
    height:769px;
    background:#fff;
    z-index: 81;
    @include brk($t) { 
        height:auto; 
        padding-top:67px;
        padding-bottom:40px;
    }
    @include brk($m) { 
        .banner-content {
            padding-left: 12%;
            padding-right: 12%;
        }
    }
    .background {
        position: absolute;
        top:0;
        left:0;
        right:0;
        height:577px;
        background: linear-gradient( to bottom, 
            #fff 0%,
            #ededed 100%);
        @include brk($t) { height:522px; }
        @include brk($m) { height:387px; }
    }
    .btn-01 {
        position: absolute;
        top:15px;
        right:0;
        display: inline-block;
        background:#1599d6;
        border-radius:6px;
        height:63px!important;
        @include brk($t) {
            display: none;
        }
        &:hover {
            background:darken(#0a1d44, 0%);
        }
        p {
            padding-top:19px;
            color:#fff;
            cursor: pointer;
        }
        .icon {
            top:14px;
        }
    }
    ul {
        // background: green;
        height:684px;
        // display: inline-block;
        // width: calc(100%);
        position: relative;
        &:before, &:after {
            content:"";
            position: absolute;
            top:0;
            bottom:0;
            height:100%;
            width:50px;
            left:-43px;
            background: url('../assets/images/ombre-solution.png');
            background-size:contain;
            background-position:50% 50%;
        }
        &:after {
            transform: rotate(180deg);
            left:auto;
            right:-43px;
        }
        li {
            height:100%;
            height:342px;
            width:33.33%;
            float: left;
            background: #fff;
            position: relative;
            z-index: 100;
            // margin-right: 15px;
            @include brk($t) {
                width:33.33%;
            }
            @include brk($m) {
                width:100%;
                margin-bottom:10px;
                &.mobile-on {
                    height:63px;
                    margin-top: 25px;
                    a {
                        z-index: 100;
                        animation: fade 1s;
                        border-radius:8px;
                        position: relative;
                        height:63px;
                        &:hover {
                            background:darken(#14c2ab, 5%);
                        }
                        .icon {
                            background: #fff;
                            position: absolute;
                            top:12px;
                            left:10px;
                            border-radius:50%;
                            height:38px;
                            width:38px;
                        }
                        .texte {
                            padding-left: 67px;
                            padding-top:13px;
                            p {
                                font-weight: $bold;
                                font-size: 15px;
                                padding-top:8px;
                                padding-bottom:5px;
                                text-align: left;
                                padding-left:20px;
                                border-left:1px solid transparentize(#fff, 0.7);
                            }
                        }
                    }
                    &.calling {
                        margin-bottom: 40px;
                        a {
                            background: #14c2ab;
                            .icon {
                                .st0{fill:#FFFFFF;}
                                .st1{fill:#14C2AB;}
                            }
                        }
                    }
                    &.diagnostic {
                        margin-bottom: 20px;
                        a {
                            background:$touche_de_couleur;
                            .icon {
                                .st0{fill:#FFFFFF;}
                                .st1{fill:$touche_de_couleur;}
                            }
                        }
                    }
                }
            }
            &:hover {
                div.hover {display:inline-block;}
            }

        }
        .image {
            height:80%;
            &:after {
                content:"";
                width:100%;
                height:100%;
                display: inline-block;
                background:linear-gradient(to bottom,
                    transparentize(#fff, 1) 0%,
                    transparentize(#fff, 1) 75%,
                    transparentize(#fff, 0) 100%);
            }
        }
        .titre-container {
            height: 52px;
            line-height: 52px;
            position: absolute;
            left:0;
            bottom:15px;
            width:100%;
            @include brk($tp) {
                bottom:14px;
            }
            @include brk($m) {
                bottom:10px;
            }
            .titre {
                @extend .bloc;
                font-size: 17.5px;
                font-weight: $bold;
                color: #104b99;
                text-align: center;
                padding: 0 30px; 
                line-height: 1.5;
                br {
                    display: none;
                    @include brk($m) {
                        // display: inline;
                    }
                }
            }
        }
        div.hover {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background: $touche_de_couleur;
            padding:50px 19% 30px;
            display: none;
            animation: fade 0.1s;
            @include brk($t) {padding:50px 11% 30px;}
            @include brk($td) {display:none!important}
        }
        @keyframes up_fade {
            0% {
                opacity: 0;
                transform: translate3d(0px, 5px, 0);
            }
            100% {
                opacity: 1;
                transform: translate3d(0px, 0px, 0);
            }
        }
        .titre-survol {
            font-size: 20px;
            font-weight: $bold;
            color: #fff;
            text-align: center;
            line-height: 1.5;
            margin-bottom: 12px;
            animation: up_fade 0.4s;
        }
        .texte-survol p, p {
            font-size: 15px;
            font-weight: $light;
            color: #fff;
            text-align: center;
            animation: up_fade 0.5s;
        }
        .en-savoir-plus-container {
            position: absolute;
            bottom:48px;
            left:0;
            width:100%;
            text-align: center;
            p {
                display: inline-block;
                padding-bottom:0px;
                line-height: 1;
                animation: fade 0.8s;
                border-bottom:1px solid #fff;
            }
        }
    }
}



.banner.bottom .banner.solutions {
    background:#ededed;
    height:auto;
    padding-bottom: 80px;
    .btn-01 {
        background:#104b99;
        &:hover {
            background:darken(#104b99, 15%);
        }
    }
}








.banner.astuces {
    background:#ededed;
    padding:60px 0 0px;
    z-index:80;
    @include brk($t) {
        padding:110px 0 90px;
    }
    @include brk($m) {
        padding:80px 0 50px;
    }
    .content {
        position: relative;
    }
    .titre-container {
        margin-bottom: 42px;
        .titre {
            color: $touche_de_couleur2;
            font-size: 26px;
            font-weight: $bold;
        }
    }

    ul {
        li {
            height:250px;
            width:48.1%;
            float: left;
            background:#fff;
            margin-bottom: 20px;
            animation: fade 2s;
            transition: box-shadow 0.2s;
            box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.10);
            @include brk($t) {
                width:100%;
                float: none!important;
            }
            @include brk($m) {
                height:495px;
            }
            &:nth-child(2n) {float:right;}

            .image-container {
                width:45%;
                position: relative;
                height:100%;
                float: left;
                @include brk($m) {
                    height:250px;
                    width:100%;
                    float: none;
                }
                .image {
                    animation: none;
                }
            }
            .textes-container {
                width:55%;
                height:100%;
                float: right;
                padding: 25px 30px;
                position: relative;
                @include brk($m) {
                    width:100%;
                    height:250px;
                    float: none!important;
                }
                .expertise {
                    font-size: 9.5px;
                    color:#a1a1a1;
                    letter-spacing: 2px;
                    font-weight: $semi-bold;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                }
                .titre-post {
                    font-size: 16.5px;
                    font-weight: $semi-bold;
                    color:$touche_de_couleur;
                }
                .lire-la-suite {
                    color:$touche_de_couleur2;
                    font-size:13px;
                    font-weight: $bold;
                    position: absolute;
                    bottom:20px;
                    left:30px;
                    opacity: 0;
                    transition: opacity 0.3s;
                    @include brk($t) {
                        opacity: 1;
                        bottom:29px;
                    }
                }
            }
            &:hover {
                box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.15);
                .lire-la-suite {
                    opacity: 1;
                }
            }
        }
    }
}



















.btn-01 {
    background: $touche_de_couleur;
    padding:0!important;
    margin-left:0!important;
    &:hover, &:focus {
        background: darken($touche_de_couleur, 15%);
        .icon span {transform:translate3d(1px,0,0);}
    }
    p {
        color:#fff;
        font-weight: $bold;
        font-size: 16.5px;
        padding:16px 90px 16px 30px;
        display: inline-block;
        user-select:none;
        text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
    }
    .icon {
        font-size: 30px;
        color:#fff;
        width:52px;
        text-align: center;
        font-weight: $normal;
        height:34px;
        padding-top:2px;
        position: absolute;
        top:13px;
        line-height: 1;
        right:6px;
        border-left:1px solid rgba(255,255,255,0.3);
        span {
            transition: transform 0.5s;
            display: inline-block;
            text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
        }
    }
    &:after {
        display: none!important;
    }
}

















.banner.logos {
    background:#fff;
    // text-align:center;
    padding: 80px 0 100px;
    .titres-section {
        margin-bottom:60px;
        .titre {
            font-size: 35px;
            color: #104b99;
            font-weight: 700;
            position: relative;
            margin-bottom: 60px;
            text-align: center;
            &:before {
                // content:"";
                width:57px;
                height:1px;
                display: inline-block;
                position: absolute;
                left:-90px;
                top:28px;
                background:#1599d6;
                @include brk($l) {
                    display: none;
                }
            }
            @include brk($t) {
                margin-bottom: 40px;
            }
            @include brk($m) { 
                font-size: 27.5px;
                line-height: 1.2;
            }
        }
    }
    ul {
        width: 100%;
        display:inline-block;
        li {
            width: 25%;
            display:inline-block;
            float:left;
            text-align: center;
            border-top:1px solid transparentize(#000, 0.9);
            border-right:1px solid transparentize(#000, 0.9);
            @for $i from 1 through 10 {
                &:nth-child(#{$i})  { 
                    animation-delay: ((0.1s * $i) - 0.05s);
                }
            }
            @include brk($m) {
                width: 50%;
            }
            a {
                height:200px;
                line-height:200px;
                // filter: saturate(0%);
                // opacity:0.6;
                cursor:default;
                width: 65%;
                text-align: center;
                @include brk($tp) {
                    height:150px;
                    line-height:150px;
                }
                .image-container {
                    height: 100px;
                    display: flex;
                    align-tems: center;
                    @include brk($m) {
                        height:50px;
                    }
                    img {
                        object-fit: contain;
                        max-height:100%;
                        width: 100%;
                        height: auto;
                    }
                }
                &.has-href:hover {
                    opacity:1;
                    cursor:pointer;
                    filter: saturate(100%);
                }
            }
            &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                border-top:none;
            }
            &:nth-child(4n) a, &:last-child a {
                border-right:none;
            }
            @include brk($m) {
                &:nth-child(2n) {
                    border-right:none;
                }
                &:nth-child(3n) , &:nth-child(4n)  {
                    border-top:1px solid transparentize(#000, 0.9);
                }
            }
        }
    }
    
}