.format {
    @import "4__format";
}

.fadeIn {
    opacity: 0;
}

.content {
    height:100%;
    display: inline-block;
    width:100%;
    position: relative;
}

.stick {
    transition: transform 0.5s;
    position: absolute;
    left:0;
    top:0;
    z-index:100;
    @include breakpoint ($tablet){position: absolute!important;}
}

.preload {display:none!important;}





.clearfix::before,
.clearfix::after {
    content:"";
    display: block;
}
.clearfix::after {
    clear:both
}







// WHEN VISIBLE 
// -----------------------------------------


*[when-visible="fade"] {
    opacity: 0;
}

.fade-in-when-visible {
    opacity: 1;
}




// DEFAULTS DIVS
// --------------------------------------------------------

.banner {
    display:inline-block;
    width:100%;
    clear:both;
    position:relative;
}

.banner-content {
    max-width:$bannerContent-maxWidth-desktop;
    padding: 0 $padding-desktop;
    margin: 0 auto;
    height:100%;
    transition: max-width 1s;
    position: relative;
    @include brk($extra){max-width:$bannerContent-maxWidth-extra}
    @include brk($t){padding-left: $padding-tablet; padding-right: $padding-tablet;}
    @include brk($m){padding-left: $padding-mobile; padding-right: $padding-mobile;}
}



.bloc-container {
    height:100%;
}

.bloc {
    display: inline-block;
    vertical-align: middle;
    line-height: normal!important;
    width:100%;
}

.image {
    background-position:50% 50%;
    background-size:cover;
    width:100%;
    height:100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: fade 1.2s;
}


.flexible {
    display: inline-block;
    width: 100%;
    li {
        width: 100%;
        display: inline-block;
    }
    .flex {
        display: inline-block;
        position: relative;
        width:100%;
        animation: fade 0.5s;
        .image {animation: fade 1.2s;}
        &.flex-texte {margin-bottom: 35px;}
        &.flex-citation {margin-bottom: 60px;}
        .lire-la-suite {
            margin-top: 20px;
            color:#fff;
            border-radius: 10px;
            text-transform: uppercase;
            font-size: 10px;
            letter-spacing: 2.5px;
            font-weight: $bold;
            padding:20px 30px;
        }
    }
}


.overlay {
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
}

.icon {
    display: inline-block;
}
svg {
    height:100%;
    width:100%;
}

.inside {
    width:100%;
    height:100%;
    position:relative;
}

.abs {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
}

.break-word {
    overflow-wrap: break-word;
    display: block;
}

.clearfix:before,
.clearfix:after {
    content:"";
    display: block;
}
.clearfix:after {
    clear:both
}







// COLONNES
// -----------------------------------------


.col {
    width:50%;
    position: relative;
    height: 100%;
    float: left;
    &.left {float:left}
    &.right {float:right}
}


.col-left {
    display: inline-block;
    position: relative;
    width:$width-left;
    float: left;
    @include brk($t) {
        width:100%;
        float: none;
    }
}

.col-right {
    display: inline-block;
    position: relative;
    width:$width-right;
    float: right;
    @include brk($t) {
        width:100%;
        float: none;
    }
}



// CACHES
// -----------------------------------------


.cache-left {
    position: relative;
    &:before {
        content:"";
        display: inline-block;
        height:100%;
        width:2000%;
        position: absolute;
        right:100%;
        top:0;
        background:inherit;
    }
}

.cache-right {
    position: relative;
    &:before {
        content:"";
        display: inline-block;
        height:100%;
        width:2000%;
        position: absolute;
        left:100%;
        top:0;
        background:inherit;
    }
}

.cache-bottom-left {
    position: relative;
    &:after {
        content:"";
        display: inline-block;
        height:10000%;
        width:2000%;
        position: absolute;
        right:0;
        top:100%;
        background:inherit;
    }
}

.cache-bottom {
    position: relative;
    &:after {
        content:"";
        display: inline-block;
        height:10000%;
        width:100%;
        position: absolute;
        left:0;
        top:100%;
        background:inherit;
    }
}

.cache-bottom-right {
    position: relative;
    &:after {
        content:"";
        display: inline-block;
        height:10000%;
        width:2000%;
        position: absolute;
        left:0%;
        top:100%;
        background:inherit;
    }
}



// ON - OFF
// --------------------------------------------------------


.block {
    display: block!important;
}

.laptop-on {
    display:none!important;
    @include brk($l){display:block!important;}
}

.laptop-off {
    @include brk($l){display:none!important;}
}

.tablet-on {
    display:none!important;
    @include brk($t){display:block!important;}
}

.tablet-off {
    @include brk($t){display:none!important;}
}

.mobile-on {
    display:none!important;
    @include brk($m){display:block!important;}
}
.mobile-off {
    @include brk($m){display:none!important;}
}





// PADDINGS
// --------------------------------------------------------


.tablet-paddings {
    @include brk($t) {
        padding-left: $padding-tablet !important;
        padding-right: $padding-tablet !important;
    }
    @include brk($m) {
        padding-left: $padding-mobile !important;
        padding-right: $padding-mobile !important;
    }
}

.mobile-paddings {
    @include brk($m) {
        padding-left: $padding-mobile !important;
        padding-right: $padding-mobile !important;
    }
}

.tablet-paddings-off {
    @include brk($t) {
        padding-left:0;
        padding-right:0;
    }
}

.paddings {
    padding: 0 $padding-desktop;
    @include brk($t){padding-left: $padding-tablet; padding-right: $padding-tablet;}
    @include brk($m){padding-left: $padding-mobile; padding-right: $padding-mobile;}
}


