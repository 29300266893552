



.page.contact form.prefill {
    z-index: 80;
    height:82px;
    width:100%;
    display: block;
    margin: 55px auto 0;
    max-width:824px;
    border-radius:4px;
    // overflow: hidden;
    position: relative;
    box-shadow: 0px 3px 20px 0px rgba(39, 51, 66, 0.3);
    display: none;
    &.visible {
        display: block;
        animation: fade 1.5s;
    }
    @include brk($td) {
        max-width: none;
    }
    @include brk($m) {
        height:auto;
    }
    // &:before {
    //     content:"";
    //     display: inline-block;
    //     width:24px;
    //     height:18px;
    //     background: url('../assets/images/icon-envelop.svg');
    //     background-position:50% 50%;
    //     background-size:contain;
    //     position: absolute;
    //     left:35px;
    //     top:33px;
    //     z-index: 100;
    //     @include brk($tp) {display:none}
    // }
    input[type="text"] {
        float: left;
        height:100%;
        width:60%;
        background: #e9f1f6;
        padding-left:44px;
        padding-top: 2px;
        position: relative;
        border-top-left-radius:6px;
        border-bottom-left-radius:6px;
        // transition: background 0.5s !important;
        @include brk($t) {
            width: 58%;
        }
        @include brk($m) {
            border-radius:6px;
            height:82px;
            width:100%;
            margin-bottom: 6px;
            padding-left:0px;
            text-align: center!important;
        }
        &:focus, &.focus {
            background: #fff;
        }
    }
    button[type="submit"] {
        float: left;
        height:100%;
        width:40%;
        background:#4797d0;
        color:#fff;
        border-top-right-radius:6px;
        border-bottom-right-radius:6px;
        &:hover, &:focus {
            background:$touche_de_couleur2;
        }
        p {
            padding-top:27px;
        }
        .icon {
            top:20px;
            padding-top:6px;
            height:45px;
            width: 45px;
        }
        @include brk($t) {
            width: 42%;
        }
        @include brk($m) {
            border-radius:6px;
            height:82px;
            width:100%;
            p {
                padding-right:30px;
                margin-left: -35px;
            }
        }
    }
    .tooltip {
        position: absolute;
        top:-44px;
        left:45px !important;
        @include brk($m){ 
            left:calc(50% - 117px) !important;
        }
    }
    
    /**************  PLACEHOLDER ADJUST   ***************/


    input::placeholder {
        font-size: 16px;
        font-weight: $normal;
        color: transparentize(#000, 0.5);
        text-align: left;
        transition: color 0.3s;
        @include brk($t){ font-size:16px; }
        @include brk($m){ font-size:16px; text-align: center;}
    }
    input:focus, textarea:focus {
        &::placeholder { 
            color: transparentize(#000, 0.8);
        }
    }

}




// FORM BACKGROUND 
$contact_form_background: #e6e6e6;


// FONT-SIZES
$input_fontsize-desktop: 19px;
$input_fontsize-tablet: 18px;
$input_fontsize-mobile: 17px;
$textarea_fontsize-desktop: 17px;


// PADDING-LEFT
$field_padding_left: 44px !important;


$box_shadow: 1px 1px 4px 0px transparentize(#000, 0.95);




.contact-form {
    margin-top: 0px;
    margin-bottom:40px;
    padding: 0 10% 0 0;
    display: inline-block;
    width: 100%;
    min-height:800px;
    // overflow:hidden;
    @include breakpoint ($tablet) {
        padding:0;
    }
    
    .titre-section-container {
        position: relative;
        padding-right: 180px;
        @include brk($m) {
            padding-right: 0;
        }
        .sur-titre {
            font-size: 21px;
            padding-bottom:15px;
            font-weight: $bold;
            color:#104b99;
            line-height: 1.3;
            @include breakpoint ($mobile) {
                padding-right: 0%;
            }
        }
        .titre-section {
            font-size:19px;
            font-weight: $light;
            color:$touche_de_couleur;
            letter-spacing: 0px;
            line-height: 1.6;
            animation: fade 1.5s;
            position: relative;
            @include breakpoint ($tablet) {
            }
            @include breakpoint ($mobile) {
                line-height: 1.5;
                font-size:19px;
                padding-right:18%;
            }
        }
        .etape-container {
            position: absolute;
            top: 7px;
            right: 0;
            text-align: right;
            @include brk($m) {
                position: relative;
                top: auto;
                right: auto;
                text-align: left;
                margin-top: 20px;
            }
            .etape {
                color:#818181;
                font-size: 16px;
                display: none;
                &.visible {
                    display: block;
                    animation: fade 1.5s;
                }
                span {
                    font-weight: $bold;
                    color:#104b99;
                }
            }
        }
    }
    form.full {
        width:100%;
        max-width: 900px;
        margin-top: 60px;
        min-height:280px;
        margin-bottom: 45px;
        display: none;
        &.visible {
            display: block;
            animation: fade 2s;
        }
        @include brk($tablet) {
            max-width: none;
        }
        @include brk($mobile){}
        .container {
            display: inline-block;
            width:100%;
            margin-bottom: 10px;
            position: relative;
            label {
                display: inline-block;
                font-size: 14px;
                margin-bottom:20px;
                letter-spacing: 1px;
                color:#555868;
                font-weight: $bold;
                display: none;
            }

            
            input, textarea, select {
                font-weight: $normal;
                z-index: 10;
                width:100%;
                padding:0 20px;
                letter-spacing: 1px;
                transition: border 0.5s;
                border:1px solid rgba(0,0,0,0);
                transition: border 0.5s, box-shadow 0.15s, padding-left 0.3s;
                border-radius:12px;
                color:$touche_de_couleur2;
                background:#fff;
                position: relative;
                box-shadow: $box_shadow;
                &:focus {
                    // border:1px solid transparentize($touche_de_couleur2, 0.8);
                    box-shadow: 2px 2px 4px 1px transparentize(#000, 0.9) !important;
                }
            }

            input {
                font-size: $input_fontsize-desktop;
                will-change: box-shadow, background, padding-left;
                @include brk($td) { font-size: $input_fontsize-tablet; }
                @include brk($m) { font-size: $input_fontsize-mobile; }
            }

            input, select {
                height:54px;
                @include brk($m) { height:45px; }
            }


            // FIX FOR CHROME AUTOFILL
            input, textarea {
                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0px 1000px #fff inset, $box_shadow !important;
                    -webkit-text-fill-color: $touche_de_couleur2 !important;
                    border: 1px solid #fff;
                }
            }


            // Turn Off Number Input Spinners
            input[type=number]::-webkit-inner-spin-button, 
            input[type=number]::-webkit-outer-spin-button { 
                -webkit-appearance: none; 
                margin: 0; 
            }


            &.nom, &.email, &.code-postal {width:49.3%;float:left;}
            &.prenom, &.phone, &.ville {width:49.3%;float:right;}
            @include brk($m) {
                width:100% !important;
                float: none!important;
                clear: both;
            }
        }
        textarea {
            font-size: $textarea_fontsize-desktop;
            resize:none!important;
            padding-top:30px!important;
            padding-right: $field_padding_left;
            text-align: justify;
            line-height: 1.5;
            will-change: box-shadow, background, height, padding-left;
            height:auto;
            transition: border 0.3s, height 0.5s, min-height 0.5s, padding-left 0.3s !important;
            max-height:500px;
            min-height: 150px;
            &:focus { min-height: 170px; }   
            @include brk($m) {
                text-align: left;
            }
        }
        .container.message {
            margin-bottom: 15px;
        }
        .champs-requis {
            text-align: right;
            margin-bottom: 0px;
            float: right;
            p {
                font-size: 14px;
                font-weight: $light;
                font-style: italic;
                color:$touche_de_couleur;
            }
            @include brk($m) {display:none;}
        }
        button[type="submit"] {
            // position: absolute;
            // bottom:50px;
            // left:15%;
            min-width:250px;
            text-align: left;
            padding: 0 0 0 40px;
            background:$touche_de_couleur;
            font-weight: $bold;
            // border-radius:12px;
            margin-top: 10px;
            color:#fff;
            height:60px;
            font-size:15px;
            letter-spacing: 1px;
            text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
            @include breakpoint ($mobile) {width:100%;}
            &:hover, &:focus {
                background: $touche_de_couleur2;
            }
            .icon {padding-top:0;}
            &.message-sent {
                background: green!important;
                p {
                    padding-right:20px;
                    &.sent {animation: fade 0.5s;}
                }
            }
        }
    }
}












.response_box-container {
    position: relative;
    overflow: hidden;
    height:40px;
    &.ng-enter {
        transition: opacity 1.5s, height 1s; 
        height:0; 
        opacity:0; 
        &.ng-enter-active {
            opacity:1; 
            height:40px; 
        }
    }
    &.ng-leave {
        transition: opacity 2.5s, height 2.5s; 
        height:40px; 
        opacity:1; 
        &.ng-leave-active {
            opacity:0; 
            height:0px; 
        }
    }
    .response_box {
        display: inline-block;
        position: absolute;
        top:0;
        left:0;
        font-size: 14px;
        width:100%;
        letter-spacing: 0px;
        font-style: italic;
        &.please-wait {color:#333}
        &.error {color:red}
    }
}

















// TOOLTIP   ---------------------------------

$tooltip_red: mix(red, #b46c6b, 28%);
$tooltip_orange: mix(orange, #dbb36d, 15%);

@keyframes show_tooltip {
    0% {opacity:0.8; transform: scaleY(0) translateY(30px)}
    70% {transform: scaleY(1) translateY(-1px)}
    100% {opacity:1; transform: scaleY(1) translateY(0px)}
}
@keyframes show_tooltip_triangle {
    0% {transform: translateY(-20px)}
    20% {transform: translateY(-20px)}
    100% {transform: translateY(0px)}
}

form.ng-submitted {
    div#gdpr_container.focus-submitted .tooltip,
    input.focus-submitted + .tooltip,
    textarea.focus-submitted + .tooltip {display:inline-block;}
}

form .tooltip {
    position: absolute;
    top:-45px;
    left:19px;
    will-change: transform, opacity;
    box-shadow: 0px 0px 4px 1px transparentize(#000, 0.91) !important;
    transform: translate3d(0,0,0);
    display:none;
    z-index: 20;
    animation: show_tooltip 0.25s;
    span {
        padding:15px 19px;
        display: inline-block;
        color:#fff;
        font-size: 12px;
        animation: fade 0.2s;
        cursor:default;
    }
    >div {
        // border-top-right-radius:2px;
        // border-top-left-radius:2px;
    }
    >div::after {
        content:"";
        transform: translate3d(0,0,0);
        animation: show_tooltip_triangle 0.3s;
        position: absolute;
        left:0;
        bottom:-9px;
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid green;
    }
    .files-missing, .gpdr-missing,
    [ng-message="required"] {
        background: $tooltip_red;
        &::after {border-left-color:$tooltip_red;}
    }
    [ng-message="pattern"] {
        background: $tooltip_orange;
        &::after {border-left-color:$tooltip_orange;}
    }
}





// BACGROUND CHANGE -----------------------------------

.contact-form form.ng-submitted {
    input.ng-invalid,
    textarea.ng-invalid {
        background:mix($contact_form_background, $tooltip_red, 91%)!important;
    }
    input.ng-invalid-pattern,
    textarea.ng-invalid-pattern {
        background:mix($contact_form_background, $tooltip_orange, 85%)!important;
    }
}



















// REQUIRED ICONS ---------------------------------


.page.contact form {
    .container {
        &.input .icon {
            height:100%; 
        }
        .icon {
            position: absolute;
            z-index: 20;
            top:0.5px;
            will-change: opacity, transform;
            &.ng-enter {
                opacity:0;
                transform:scale(0);
                transition: opacity 0.25s 0.05s, transform 0.2s 0.05s;
                &.ng-enter-active {
                    transform:scale(1);
                    opacity:1; 
                }
            }
        }
        .icon-checked {
            width:14px;
            left:19px;
            .st0 {fill:transparentize(green, 0)}
        }
        .icon-circle {
            width:9px;
            left:20px;
            .st0{fill:mix(orange, $tooltip_orange, 90%)}
        }
        .icon-cross {
            width:10px;
            left:19px;
            .st0{
                fill:none;
                stroke:mix(red, $tooltip_red, 60%);
                stroke-width:9;
                stroke-miterlimit:10;
            }
        }
        &.textarea {
            .icon-checked {top: 32.5px;}
            .icon-circle, .icon-cross { top: 31.5px;}
        }
    }

    input {
        &.prefill,
        &.ng-invalid.cross-has-shown,
        &.ng-invalid-pattern.ng-dirty.blured,
        &.ng-invalid-pattern.ng-dirty.blured,
        &.ng-valid.blured.ng-dirty {
            padding-left: $field_padding_left;
        }
    }

    textarea {
        &.ng-valid, &.cross-has-shown {
            padding-left: $field_padding_left;
        }
    }

    &.ng-submitted {
        input, textarea {
            &.ng-invalid-pattern {
                padding-left: $field_padding_left;
            }
            &[type="tel"], &[type="email"] {
                &.ng-valid {
                    padding-left: $field_padding_left;
                }
            }
        }
    }
}



.front-page form,
.page.contact form.prefill,
.post.single form {
    &.input .icon {
        height:100%; 
    }
    .icon {
        position: absolute;
        z-index: 20;
        top:32px;
        will-change: opacity, transform;
        &.ng-enter {
            opacity:0;
            transform:scale(0);
            transition: opacity 0.25s 0.05s, transform 0.2s 0.05s;
            &.ng-enter-active {
                transform:scale(1);
                opacity:1; 
            }
        }
    }
    .icon-checked {
        width:14px;
        left:19px;
        .st0 {fill:transparentize(green, 0)}
    }
    .icon-circle {
        width:9px;
        left:20px;
        .st0{fill:mix(orange, $tooltip_orange, 90%)}
    }
    .icon-cross {
        width:10px;
        left:19px;
        .st0{
            fill:none;
            stroke:mix(red, $tooltip_red, 60%);
            stroke-width:9;
            stroke-miterlimit:10;
        }
    }
    &.textarea {
        .icon-checked {top: 32.5px;}
        .icon-circle, .icon-cross { top: 31.5px;}
    }


    input {
        &.prefill,
        &.ng-invalid.cross-has-shown,
        &.ng-invalid-pattern.ng-dirty.blured,
        &.ng-invalid-pattern.ng-dirty.blured,
        &.ng-valid.blured.ng-dirty {
            padding-left: $field_padding_left;
        }
    }

    textarea {
        &.ng-valid, &.cross-has-shown {
            padding-left: $field_padding_left;
        }
    }

    &.ng-submitted {
        input, textarea {
            &.ng-invalid-pattern {
                padding-left: $field_padding_left;
            }
            &[type="tel"], &[type="email"] {
                &.ng-valid {
                    padding-left: $field_padding_left;
                }
            }
        }
    }
}
























// WHEN FORM IS COMPLETE & SUBMITTED  ->  DISABLED -------------------

$disabled_color: transparentize($touche_de_couleur2, 0.75) !important;

.contact-form form[disabled="disabled"] {
    .container input, .container textarea {
        color: $disabled_color;
        user-select:none;
        cursor: default;
        padding-left:44px;
        &:disabled:-webkit-autofill {
            -webkit-text-fill-color: $disabled_color;
        }
    }
}





/**************  PLACEHOLDER ADJUST   ***************/

.contact-form {
    input::placeholder, textarea::placeholder {
        font-size: 16px;
        font-weight: $normal;
        color: transparentize($touche_de_couleur2, 0.5) !important;
        text-align: left;
        letter-spacing: 0px;
        // opacity: 1;
        // transition: opacity 0.3s;
        // z-index: 10;
        // line-height: 1;
        @include brk($td){ font-size: $input_fontsize-tablet; }
        @include brk($m){  font-size: $input_fontsize-mobile; }
    }
    input:focus, textarea:focus {
        &::placeholder { opacity: 0.35!important; }
    }

}

















div.gdpr-container {
    width: 100%;
    display:inline-block;
    margin:0px auto 20px !important;
    position: relative;
    @include brk($td) {
        width: 70%;
    }
    @include brk($tp) {
        width: 100%;
    }
    @include brk($m) {
    }
    label[for="gdpr"] {
    }
    .tooltip.gdpr {
        left:35px;
        top: -55px;
    }
    p.note {
        padding-left:40px;
        user-select:none;
        z-index: 11;
        font-family: $f1;
        line-height: 1.9;
        @include brk($m) { 
            padding-left:40px;
        }
        a {
            -webkit-text-stroke: #fff;
            text-decoration: underline;
        }
        &, a {
           font-size:13px; 
           @include brk($m) { 
                font-size: 11px;
            }
        }
    }
    a:hover {
        color:$touche1;
    }
    input[type="checkbox"] {
        display: none;
        + label {
            .case {
                cursor:pointer;
                width:26px;
                height:26px;
                border-radius:50%;
                text-align: center;
                position: absolute;
                z-index: 11;
                left:0px;
                // top: 50%;
                // transform: translateY(-50%);
                top: 0;
                border: 1px solid transparentize(#000, 0.85);
                padding:7px;
                background:#fff;
                @include brk($m) {
                }
                &:hover {
                    background: transparentize(#000, 0.96);
                    border: 1px solid transparent;
                };
            }
            .case span {
                background: $touche2;
                display: block;
                height:100%;
                border-radius:50%;
                transition: transform 0.2s;
                transform: scale(0);
            }
        }
        &:checked + label {
            .case span { 
                transition: transform 0.15s;
                transform: scale(1);
            }
        }
    }
}



form.prefill div.gdpr-container {
    margin-top: 15px !important;
}


.front-page div.gdpr-container {
    margin-top: 10px !important;
    width:auto;
    * {
        color:#fff;
    }
    a:hover {
        color:lighten($touche1, 15%);
    }

    input[type="checkbox"] {
        + label {
            .case {
                &:hover {
                    background: lighten(#000, 95%);
                };
            }
        }
    }
}