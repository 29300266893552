



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 280px;
$page_wrapper-bannerTop-laptop: 220px;
$page_wrapper-bannerTop-tablet: 200px;
$page_wrapper-bannerTop-mobile: 220px;

.page.contact .banner.top {
	// margin-top: $header-height;
	transition: height 1s;
	height:$page_wrapper-bannerTop-desktop;
	@include brk($l) { height:$page_wrapper-bannerTop-laptop; }
	@include brk($t) { 
		height:$page_wrapper-bannerTop-tablet; 
		// margin-top: $header-height-tablet;
	}
	@include brk($m) { 
		height:$page_wrapper-bannerTop-mobile; 
		// margin-top: $header-height-mobile;
	}
}
.page.contact .banner.top .bloc-container {
	transition: line-height 1.8s;
	line-height:$page_wrapper-bannerTop-desktop;
	@include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
	@include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
	@include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}



.page.contact {
    background:#fff;
}


.page.contact .banner.top {
	position: relative;
	.image {
		background-position: 50% 32% !important;
        background-size: cover !important;
        @include brk($t) {
			background-position: 0% 32% !important;
		}
	}
	.bloc {
		@include brk($t) {
			padding: 10px 0 0 0;
		}
		@include brk($m) {
			padding: 0px 0 0 0;
		}
	}
	.sur-titre {
		animation: fade 2.5s;
		font-size: 19px;
		color:$touche_de_couleur;
		letter-spacing: 0.5px;
		font-weight: $light;
		position: relative;
		z-index: 4;
        margin-bottom:6px;
        line-height: 1.35;
        br {display:none}
		@include brk($t) { 
			animation: fade_80 2.5s;
			opacity:0.8;
            br{display:inline;}
			// color:#fff;
		}
		@include brk($m) { font-size: 17px; }
	}
	.titre {
		animation: fade 2s;
		font-size:35px;
		font-weight: $bold;
		letter-spacing: 0.5px;
		color:#555868;
        line-height: 1.25;
		position: relative;
		z-index: 4;
		&:before {
            content:"";
            display: inline-block;
            height:3px;
            width:20px;
            position: absolute;
            left:-40px; top:27px;
            background:$touche_de_couleur;
            @include breakpoint ($t) {
                display: none;
            }
        }
		@include brk($t) { font-size: 30px; }
		@include brk($m) { font-size: 25px; }
	}
	
}










.aplat-contact {
	position: absolute;
	right:0px;
	bottom:0px;
	background:#104b99;
	height:82px;
	width:30%;
	z-index: 10;
	animation: fade 1s;
	@include brk($t) {
		position: relative;
		width:100%;
		bottom:0px;
		z-index: 40;
	}
	@include brk($t) {
		height:70px;
	}
	.icon {
		height:56px;
		width:56px;
		border-radius:50%;
		position: absolute;
		left:34px;
		top:14px;
		background: transparentize(#fff,0.85);
		svg {
			transform: scale(0.41);
			path {
				fill:#fff;
			}
		}
		@include brk($t) {
			left:5.5%;
			top:8px;
		}
	}
	p {
		color:#fff;
		font-weight: $bold;
		font-size: 20.5px;
		padding-left:120px;
		padding-top:26px;
		@include brk($t) {
			padding-left: calc(5.5% + 80px);
			font-size: 18px;
			padding-top:22px;
		}
	}
}






.page.contact .banner.top {
	.image { 
		animation: fade 1s; 
		background-position: 50% 20%;
	}
}








.page.contact .banner.medium {
	overflow:visible;
	background:#f2f8f8;
	padding-top:0;
	animation:none;
	.banner-content {
		@include brk($t) { padding:0 }
		.content {
			position: relative;
		}
	}
	.col-left {
		min-height: 300px;
		background:#ffffff;
		float: right;
		width:30%;
		z-index: 21;
		animation: fade 2s;
		@extend .cache-right;
		@extend .cache-bottom-right;
		@include brk($t) { 
			width: 100%; 
			padding-left:6%;
			&:after {display:none;}
		}
		@include brk($m) {  
			width: 100%;  
			padding-left:$padding-mobile;
			padding-right:$padding-mobile;
		}
		.email {
			margin-top: 18px;
			margin-bottom: 20px;
			color:$touche_de_couleur;
		}
		.inside {
			padding: 100px 18% 80px 50px;
			padding-right:18%;
			text-align: left;
			animation: fade 1s;
			@include brk($t) { 
				padding: 50px 0% 40px 0;
			}
			@include brk($m) { 
				text-align: left; 
				padding: 40px 0% 30px 0;
			}
		}
		.nom-entreprise, .nom-complet {
			font-size: 22.5px;
			color:$touche_de_couleur2;
			font-weight: $bold;
			line-height: 2;
			span {
				font-weight: $light;
				color:#4a4337;
			}
		}
		.nom-complet {
			padding-left:43%;
			margin-bottom:28px;
			@include brk($t) {  padding-left:10%; }
			@include brk($m) {  padding-left:0%; }
		}
		p, a, h6 {
			width:100%;
			color:#797979;
			font-size: 15px;
			line-height: 1.95;
			font-weight: $light;
			letter-spacing: 0px;
		}
		span, h6 {
			font-weight:$bold;
		}
		h6 {margin-top:30px;}
		.adresses-container {
            ul {
                display: inline-block;
                width: 100%;
            }
            li {
                padding-bottom: 15px;
                border-bottom: 1px solid transparentize(#000, 0.9);
                &:last-child {
                    border-bottom:none;
                }
                @include brk($t) {
                    width:24%;
                    float: left;
                    border-bottom:none;
                }
                @include brk($tp) {
                    width:50%;
                }
                @include brk($m) {
                    width:100%;
                }
            }
            .province {
                font-size: 17.5px;
                font-weight: $bold;
                color:#555868;
                line-height: 1.4;
                margin-top: 16px;
                margin-bottom: 7px;
            }
            .adresse, .code-postal-ville {
                font-size: 14.5px;
                font-weight: $light;
                color:#93a0b1;
            }
            .telephone {
                color:$touche_de_couleur;
                font-weight: $bold;
                padding-left:31px;
                position: relative;
                .icon {
                    width:22px;
                    height:22px;
                    position: absolute;
                    left:0;
                    top:-1px;
                    .st0 {
                        fill:$touche_de_couleur;
                    }
                }
            }

			a {display:inline; position: relative;}
			a:before {
                content:"";
                display: inline-block;
                position: absolute;
                bottom:-2px;
                left:32px;
                height:2px;
                width:0;
                // z-index: 10;
                // opacity: 0;
                transition: opacity 0.5s, width 0.2s;
                background: darken($touche_de_couleur, 10%);
            }
            a:hover {
                &:before {
                    width:82%;
                    opacity: 0.5;
                }
            }
		}
		.petit-trait {
			height: 2.5px;
			background:$touche_de_couleur;
			margin-top: 17px;
			margin-bottom:18px;
			width:21px;
		}
		.trait {
			height: 16px;
			border-bottom:1px solid transparentize(#000, 0.9);
			margin-bottom:16px;
			width:80%;
			@include brk($t) {  display: none }
		}
		.email {
            position: relative;
            width:auto;
            &:before {
                content:"";
                display: inline-block;
                position: absolute;
                bottom:3px;
                left:0;
                height:2px;
                width:0;
                // z-index: 10;
                // opacity: 0;
                transition: opacity 0.5s, width 0.2s;
                background: darken($touche_de_couleur, 10%);
            }
            &:hover {
                &:before {
                    width:100%;
                    opacity: 0.5;
                }
            }
        }
	}
	.col-right {
		background:#e6e6e6;
		padding-top: 70px;
		padding-bottom: 70px;
		float: left;
		width:70%;
		animation: fade 1.5s;
		@extend .cache-left;
        @extend .cache-bottom-left;
		@include brk($t) { 
			padding: 60px 6% 0px;
			width: 100%; }
		@include brk($m) {
			padding: 55px 6% 40px;
			padding-left:$padding-mobile;
			padding-right:$padding-mobile;
			width: 100%;
		}
		.format {
			padding: 0px 0 50px 12%;
		    display: inline-block;
		    width: 100%;
		    @include brk ($tablet) {
		        padding: 0 9% 55px;
		    }
		    @include brk ($mobile) {
		        padding: 0 6%;
		        margin-top: 40px;
		        margin-bottom:40px;
		    }
		}
	}
}











