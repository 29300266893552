/* perfect-scrollbar v0.6.4 */

.ps-container{
	-ms-touch-action:none;
	overflow:hidden !important
}
// .ps-container.ps-active-x>.ps-scrollbar-x-rail,
.ps-container.ps-active-y>.ps-scrollbar-y-rail{
	display:block
}
.ps-container.ps-in-scrolling{
	pointer-events:none
}
// .ps-container.ps-in-scrolling.ps-x>.ps-scrollbar-x-rail{
// 	background-color:#eee;
// 	opacity:0.9
// }
// .ps-container.ps-in-scrolling.ps-x>.ps-scrollbar-x-rail>.ps-scrollbar-x{
// 	background-color:#999
// }
.ps-container.ps-in-scrolling.ps-y>.ps-scrollbar-y-rail{
	background-color:#eee;
	opacity:0.9
}
.ps-container.ps-in-scrolling.ps-y>.ps-scrollbar-y-rail>.ps-scrollbar-y{
	background-color:#999
}
// .ps-container>.ps-scrollbar-x-rail{
// 	display:none;
// 	position:absolute;
// 	-webkit-border-radius:4px;
// 	-moz-border-radius:4px;
// 	-ms-border-radius:4px;
// 	border-radius:4px;
// 	opacity:0;
// 	-webkit-transition:background-color .2s linear,opacity .2s linear;
// 	-moz-transition:background-color .2s linear,opacity .2s linear;
// 	-o-transition:background-color .2s linear,opacity .2s linear;
// 	transition:background-color .2s linear,opacity .2s linear;
// 	bottom:3px;height:8px
// }
// .ps-container>.ps-scrollbar-x-rail>.ps-scrollbar-x{
// 	position:absolute;
// 	background-color:#aaa;
// 	-webkit-border-radius:4px;
// 	-moz-border-radius:4px;
// 	-ms-border-radius:4px;
// 	border-radius:4px;
// 	-webkit-transition:background-color .2s linear;
// 	-moz-transition:background-color .2s linear;
// 	-o-transition:background-color .2s linear;
// 	transition:background-color .2s linear;
// 	bottom:0;
// 	height:8px
// }
.ps-container>.ps-scrollbar-y-rail{
	display:none;
	position:absolute;
	// -webkit-border-radius:4px;
	// -moz-border-radius:4px;
	// -ms-border-radius:4px;
	border-radius:4px;
	opacity:0;
	// -webkit-transition:background-color .2s linear,opacity .2s linear;
	// -moz-transition:background-color .2s linear,opacity .2s linear;
	// -o-transition:background-color .2s linear,opacity .2s linear;
	transition:background-color .2s linear,opacity .2s linear;
	// right:3px;
	right:28px!important;  // RASPBERRY template
	width:8px
}
.ps-container>.ps-scrollbar-y-rail>.ps-scrollbar-y{
	position:absolute;
	background-color:#aaa;
	// -webkit-border-radius:4px;
	// -moz-border-radius:4px;
	// -ms-border-radius:4px;
	border-radius:4px;
	// -webkit-transition:background-color .2s linear;
	// -moz-transition:background-color .2s linear;
	// -o-transition:background-color .2s linear;
	transition:background-color .2s linear;
	right:0;
	width:8px
}
.ps-container:hover.ps-in-scrolling{
	pointer-events:none
}
// .ps-container:hover.ps-in-scrolling.ps-x>.ps-scrollbar-x-rail{
// 	background-color:#eee;
// 	opacity:0.9
// }
// .ps-container:hover.ps-in-scrolling.ps-x>.ps-scrollbar-x-rail>.ps-scrollbar-x{
// 	background-color:#999
// }
.ps-container:hover.ps-in-scrolling.ps-y>.ps-scrollbar-y-rail{
	background-color:#eee;
	opacity:0.9
}
.ps-container:hover.ps-in-scrolling.ps-y>.ps-scrollbar-y-rail>.ps-scrollbar-y{
	background-color:#999
}
// .ps-container:hover>.ps-scrollbar-x-rail,
.ps-container:hover>.ps-scrollbar-y-rail{
	opacity:0.6
}
// .ps-container:hover>.ps-scrollbar-x-rail:hover{
// 	background-color:#eee;
// 	opacity:0.9
// }
// .ps-container:hover>.ps-scrollbar-x-rail:hover>.ps-scrollbar-x{
// 	background-color:#999
// }
.ps-container:hover>.ps-scrollbar-y-rail:hover{
	background-color:#eee;
	opacity:0.9
}
.ps-container:hover>.ps-scrollbar-y-rail:hover>.ps-scrollbar-y{
	background-color:#999
}