@if index($hamburger-types, squeeze) {
  /*
   * Squeeze
   */
  .hamburger--squeeze {
    .hamburger-inner {
      transform: translate3d(0px,-3px,0px);
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      // transition: margin-top 0.1s 0.14s ease,

      &::before {
        transition: top 0.1s 0.14s ease,
                    opacity 0.1s ease;
        opacity: 0;
      }

      &::after {
        transition: bottom 0.1s 0.14s ease,
                    transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {

      .hamburger-inner {
        background: $hamburger-close-layer-color ;
        transform: rotate(45deg) translate3d(1px,0px,0px);
        transition-delay: 0.14s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.1s ease,
                      opacity 0.1s 0.14s ease;
        }

        &::after {
          background: $hamburger-close-layer-color ;
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease,
                      transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }


    &.is-active.back-arrow {
      .hamburger-inner {
        background:rgba(0,0,0,0)!important;
        transform: rotate(0deg);
        // transition-duration: 0.1s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        &,
        &::before,
        &::after {
          width: $hamburger-layer-width;
          height: $hamburger-layer-height;
          background-color: $hamburger-layer-color;
          border-radius: $hamburger-layer-border-radius;
          position: absolute;
          transition-property: transform, background;
          transition-duration: $hamburger-animation-transition-duration !important;
          // transition-duration: 5s!important;
          transition-timing-function: ease-out;
        }

        // BARRE DESSUS
        &::before {
          transition: top 0.1s 0.14s ease, 
                transform 0.1s 0.14s ease, width 0.1s 0.14s ease,
                  opacity 0s ease;
          opacity: 1;
          width: 22px;
          top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1 + 7px;
          transform: translate3d($hamburger-layer-width * -0.05, 0, 0) rotate(-45deg) scale(0.7, 1);
        }

        // BARRE DESSOUS
        &::after {
          // opacity: 0;
          transition: bottom 0.1s 0.14s ease, 
                transform 0.1s 0.14s ease, width 0.1s 0.14s ease,
                        opacity 0.1s ease;
          width: 22px;
          bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1 + 4px;
          transform: translate3d($hamburger-layer-width * -0.05, 0, 0) rotate(45deg) scale(0.7, 1);
        }
      }
    }
  }
}
