// .not-visible {
//   visibility: hidden;
// }

.not-visible {
	opacity:0;
	transform: translate3d(0,10px,0);
}

// .my-animated {
// 	animation-duration: 2s;
// }

.fade-in-when-visible {
	animation-duration: 0.6s;
	animation-name: fade;
}



@keyframes fade_left_menu_l1 {
    0% {opacity:0;letter-spacing: 2px;}
    10% {opacity:0;}
    10% {transform:translate3d(50px,0px,0px)}
    20% {opacity:0;}
    55% {transform:translate3d(0px,0px,0px);}
    80% {letter-spacing: 1px;}
    100% {opacity:1;}
}


@keyframes fade_left_menu_l2_p {
    0% {opacity:0;}
    10% {transform:translate3d(50px,0px,0px)}
    55% {transform:translate3d(0px,0px,0px);}
    100% {opacity:1;}
}

@keyframes fade_left_menu_l3_p {
    0% {opacity:0;}
    10% {transform:translate3d(50px,0px,0px)}
    55% {transform:translate3d(0px,0px,0px);}
    100% {opacity:1;}
}



@keyframes fade_scale {
    0% {opacity:0;transform:scale(0)}
    10% {opacity:0;}
    50% {transform:scale(1)}
    100% {opacity:1; }
}

@keyframes fade_scale_90 {
    0% {opacity:0;transform:scale(0.9)}
    10% {opacity:0;}
    70% {transform:scale(1)}
    100% {opacity:1; }
}





@keyframes fade {
    0% {opacity:0}
    100% {opacity:1}
}
@keyframes fade_90 {
    0% {opacity:0}
    100% {opacity: 0.9}
}
@keyframes fade_80 {
    0% {opacity:0}
    100% {opacity: 0.8}
}
@keyframes fade_70 {
    0% {opacity:0}
    100% {opacity: 0.7}
}

@keyframes scale_50 {
    0% {transform:scale(0.5);}
    100% {transform:scale(1);}
}
@keyframes scale_90 {
    0% {transform:scale(0.9);}
    100% {transform:scale(1);}
}
@keyframes scale_95 {
	0% {transform:scale(0.95);}
	100% {transform:scale(1);}
}




@keyframes move_left {
    0% {transform:translate3d(100px,0px,0px)}
    100% {transform:translate3d(0px,0px,0px)}
}


@keyframes move_up {
    0% {transform:translate3d(0px,15px,0px)}
    100% {transform:translate3d(0px,0px,0px)}
}





// div[data-ui-view="inner"] {
// 	.ng-enter {transition: opacity 2s;opacity:0}
// 	.ng-enter-active {opacity:1}
// 	.ng-leave {transition: opacity 2s; opacity:1}
// 	.ng-leave-active {opacity:0}
// }