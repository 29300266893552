
// Order is respected
// Folders can be imported, using gulp-sass-bulk


@import "base/0__reset";
@import "base/1__breakpoint";
@import "base/2__fontface";
@import "base/3__variables";
@import "base/5__classes";
@import "base/6__big-divs";
@import "base/7__animations";
// @import "base/8__when-visible.scss";
// @import "base/9__loaders";

@import "admin/front";




@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/_banner-promo.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/_commun.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/_flexslider.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/_footer.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/_header.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/_headroom.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/_mobile.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/_nav-horizontal.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/_perfect-scrollbar.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/hamburger/00_hamburger.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/hamburger/01_base.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/elements/hamburger/02_squeeze.scss";

@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_404.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_archive-astuces.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_front-page.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_page-aide.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_page-contact-form.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_page-contact.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_page-enfant-solution.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_page-gdpr.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_page-merci.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_page-parent.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_page-qualite.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_pages.scss";
@import "C:/xampp/htdocs/BATIT/_PUBLIC/wp-content/themes/BATIT/__src/scss/pages/_single.scss";



