


.flex-focus_fond_bleu {
	margin-bottom:70px;
	@include brk($t) {
		margin-bottom:60px;
	}
	@include brk($m) {
		margin-bottom:50px;
	}
}





.page.single.solution, 
.page.parent.single {
	.banner.top .icon-page {
		position: absolute;
		top:37px;
		right:0px;
		height:115px;
		width:115px;
		.icon {
			animation: fade 1s;
		}
		@include brk($t) {
			height:105px;
			width:105px;
		}
		@include brk($td) {
			height:90px;
			width:90px;
		}
		@include brk($m) {
			top:32px;
			height:70px;
			width:70px;
			right:0px;
		}
	}
}