$page_wrapper-bannerTop-desktop: 400px;
$page_wrapper-bannerTop-laptop: 400px;
$page_wrapper-bannerTop-tablet: 400px;
$page_wrapper-bannerTop-mobile: 320px;




.page.single.merci {
	.banner.top {
		height:$page_wrapper-bannerTop-desktop;
	    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
	    @include brk($t) { 
	        height:$page_wrapper-bannerTop-tablet; 
	        margin-top: $header-height-tablet;
	    }
	    @include brk($m) { 
	        height:$page_wrapper-bannerTop-mobile; 
	        margin-top: $header-height-mobile;
	    }

		.textes-container {
			animation: fade 2s;
			width:100%;
			position: absolute;
			bottom:0;
			left:0;
			padding: 30px 0 0px;
		}
		.bottom {
			width:100%;
			padding: 30px 0 62px;
			border-top:1px solid transparentize(#fff, 0.8);
			@include brk($t){
				padding: 30px 0 55px;
			}
		}
		.titre {
	        animation: fade 2s;
	        font-size:50px;
	        line-height: 1.1;
	        font-weight: $thin;
	        letter-spacing: 0.5px;
	        color:#fff;
	        position: relative;
	        z-index: 4;
	        margin-bottom: 65px;
	        @include brk($t) { margin-bottom: 45px; }
	        @include brk($m) { font-size: 25px; }
	    }
	    .sous-titre p {
	    	animation: fade 2s;
	    	color:#fff;
	    	font-size: 18px;
	    	font-weight: $light;
	    	@include brk($t) {
	    		font-size: 17.5px;
	    	}
	    	@include brk($m) {
	    		font-size: 15px;
	    	}
	    	span {
	    		color:$touche_de_couleur;
	    		font-weight: $bold;
	    	}
	    }
	}
}


