

/* BODY WRAPPER
==========================*/

html {
    overflow-x: hidden;
    overflow-y: scroll;
    background:$footer-color;
    position: relative;
}

#body_wrapper {
    width:100%;
    min-height:100%;
    overflow-y: hidden;
    position: relative;
    user-select:none;
    background:$footer-color;
}

#main {
    position:relative;
    background:$main-background-color;
    overflow:hidden;
    z-index: 10;
    margin-top: -5px;
    // margin-top: $header-height;
    // @include breakpoint ($tablet) {margin-top:$header-height-tablet;}
    // @include breakpoint ($mobile) {margin-top:$header-height-mobile;}
}


.page-overlay{
    position:fixed;
    top:$header-height;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:200;
    height:100%;
    text-indent:-9999px;
    overflow:hidden;
    display:none;
    background: #000;
    opacity:0;
    transition: opacity 0.3s;
    // @include brk($t) {top:$header-height-tablet;}
    // @include brk($m) {top:$header-height-mobile;}
    // span {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     margin: -62px 0 0 -62px;
    //     width: 125px;
    //     height: 125px;
    //     background: url(images/preload_v3@2x.gif);
    //     background-size:cover;
    // }
}






 
#wpadminbar {
    background: transparentize(#23282d, 0.15)!important;
    transition: background 1s;
    &:hover {
        transition: background 0.6s;
        background: transparentize(#23282d, 0.05)!important;
    }
    .ab-sub-wrapper,
    #wp-admin-bar-dashboard,
    #wp-admin-bar-menus,
    #wp-admin-bar-themes,
    #wp-admin-bar-site-name-default,
    #wp-admin-bar-search {display:none}
}