







.page.parent .flex-intro {
	margin-bottom:80px;
	@include brk($m) {
		margin-bottom:50px;
	}
}





.page.parent .banner.solutions {
	height:auto;
	padding-top:15px;
    padding-bottom:95px !important;

    .banner-content {
    	height:auto;
	    .content {
	    	height:auto;
	    	ul {
	    		height:auto;
	    		@extend .clearfix;
	    	}
	    }
    	
    }

}



.page.parent .banner.etapes {
	padding-top:95px;
    padding-bottom:95px;
    height:auto;
}



.page.parent .flex.flex-texte_image,
.page.parent .flex.flex-image_texte {
	// padding-top: 80px;
}



.page.parent .banner.astuces {
	padding-bottom:80px;
}