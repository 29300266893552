



#header_behind_promo {
    height: 45px;
    display: none;
    &.show-promo {
        display: inline-block;
    }
    @include brk($t) {
        height: 68px;
    }
    @include brk($m) {
        // height: 80px;
    }
}






.banner.top-promo {
    background: #DD5953;
    z-index: 1000;
    display: none;
    position: fixed;
    top: 0;
    @include brk($t) {
        top: 0;
    }
    .banner-content {
        padding:0 0px 0 20px;
        @include brk($tp) { padding:0 0px 0 0px; }
    }
    .content {
        display: flex;
        align-items: center;
        min-height:45px;
        @include brk($t) {
            min-height:68px;
            top: 0;
        }
        * {
            color:#fff;
        }
        .text-container {
            opacity: 0;
            transition: opacity 0.3s ease 0s;
            margin: auto;
            max-width: 80%;
            text-align: center;
            line-height: 1;
            @include brk($m) {
                font-size: 13px;
                line-height: 1.4;
                text-align: left;
                max-width: 100%;
                padding-left: 8%;
                padding-right: 20%;
            }
            .icon {
                width: 14px;
                height: 14px;
                position: absolute;
                left: -30px;
                top: calc(50% - 0px);
                transform: translateY(-50%);
                .cls-1 { fill: #fff; }
                @include brk($tp) {
                    display: none;
                }
            }
            * {
                color:#fff;
                font-size: 13px;
                @include brk($m) {
                    font-size: 11px;
                }
            }
            .text {
                display: inline-block;
                padding-left: 20px;
                position: relative;
                line-height: 1.2;
                @include brk($m) {
                    padding-left: 0;
                }
                &::before {
                    content:"";
                    display: inline-block;
                    height: calc(100%);
                    width: 1px;
                    background: transparentize(#fff, 0.5);
                    position: absolute;
                    top: -2px;
                    left: 0;
                    @include brk($tp) {
                        display: none;
                    }
                };
            }
            a {
                display: inline-block;
                font-weight: $bold;
                line-height: 1.2;
                text-decoration: underline;
                padding:5px;
                transition: color 0.5s;
                color: transparentize(#fff, 0.1);
                &:hover {
                    color: transparentize(#fff, 0);
                }
                @include brk($td) {
                    color: transparentize(#fff, 0);
                }
                @include brk($m) {
                    padding-left: 0;
                }
            }
        }
        button.close-promo {
            height: 100%;
            width: 50px;
            position: absolute;
            top: 50%;
            right: -10px;
            transform: translateY(calc(-50% - 7px));
            opacity: 0;
            transition: opacity 0.3s ease 0s;
            @include brk($t) {
                right: 0;
                transform: translateY(calc(-50% - 0px));
                width: $header-height-tablet;
            }
            @include brk($m) {
                width: $header-height-mobile;
                
            }
            .icon {
                position: absolute;
                top: 20px;
                left: 50%;
                transform: translateX(-50%);
                width: 12px;
                height: 12px;
                @include brk($t) {
                    top: calc(50% - 4px);
                    transform: translate(-50%, -50%);
                }
                .st0 {
                    stroke:transparentize(#fff, 0.1);
                    fill:none;stroke-width:6;stroke-miterlimit:10;
                }
            }
            &:hover {
                // background: transparentize(#000, 0);
                .st0 {
                    transition: stroke 0.5s;
                    stroke-width:8 !important;
                    stroke:transparentize(#fff, 0);
                }
            }
        }
    }
    &.show-promo {
        .text-container {
            transition: opacity 0.6s ease 0.3s;
            opacity: 1;
        }
        button.close-promo {
            transition: opacity 0.6s ease 0.5s;
            opacity: 1;
        }
    }
}

