/* BANNER NAVIGATION
======================================*/


#header_behind {
    height:$header-height;
    // background:transparentize(#fff, 0.0);
    position: relative;
    transition: height 0.7s ease 0.05s;
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
    &.page {
        background: #fff;
    }
    &.page.show-promo {
        height: $header-height + 45px;
        @include brk($menu-off) {height: $header-height-tablet + 68px;}
    }
}


#header {
    height:$header-height;
    position:fixed;
    top:0px;
    left:0px; 
    z-index: 99;
    transition: height 0.7s;
    &.page {
        background: #fff;
    }
    &.show-promo {
        height: $header-height + 45px;
        >.banner-content {
            height: $header-height;
        }
        @include brk($menu-off) {height: $header-height-tablet + 68px;}
    }

   
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
    .background {
        // display: none !important;
        transition: height 0.6s, opacity 0.6s;
        &:not(.page){
            opacity: 0;
        };
        &.page {
            // background: #fff;
        }
        &.show-promo {
            // height: calc(100% + 45px);
            @include brk($t) {height: calc(100% + 68px);}
            @include brk($menu-off) {height: calc(100% + 0px);}
        }
    }
    .banner-content {
        height: 100%;
        margin: 0 auto;
        @include brk($header-getLarge) {
            margin:0; 
            padding:0 20px 0 20px;
            transition: 0.5s;
        }
        @include brk($t) {
            height:$header-height-tablet;
            padding:0 0px 0 20px;
        }
        @include brk($m) {padding-left:14px;height:$header-height-mobile;}
    }
    &.page {
        // background:transparentize(#fff, 0.04);
    }
}

#header_top {
    height:72px;
    .banner-content {
        @include brk($t) {
            padding-right:0;
        }
    }
    .logo-container {
        position: relative;
        float: left;
    }
    .logo-image {
        position: absolute;
        top:10px;
        left:0;
        background-size:contain;
        width:340px;
        height:80px;
        display: inline-block;
        margin-top:0px;
        padding-bottom:5px;
        // margin-left: -12px;
        float:left;
        // .st0{fill:#fff;fill:#fff;}
        // .st1{fill:#fff;fill:#fff;}
        // .st2{fill:#fff;fill:#cdd0d7;}
        // .st3{fill:#fff;fill:#cdd0d7;}
        .st0{fill:#FFFFFF;}
        .st1{opacity:0.8;fill:#FFFFFF;enable-background:new    ;}
        .st2{fill:#188DC4;}
        .st3{fill:#1999D6;}
        .st4{fill:none;stroke:#C0C2C4;stroke-width:0.5;stroke-miterlimit:10;}
        &.page {
            // .st0{fill:#104B99;}
            // .st1{fill:#1599D6;}
            // .st2{fill:#0E4285;}
            // .st3{fill:#178CC3;}
            // .texte {
            //     .st1 {fill:#68bde4;}
            // }
            .st0{fill:#0F4C98;}
            .st1{fill:#1C4584;}
            .st2{fill:#188DC4;}
            .st3{fill:#1999D6;}
            .st4{fill:none;stroke:#C0C2C4;stroke-width:0.5;stroke-miterlimit:10;}
        }
        @include brk($t) {
            top: 4px;
            width:300px;
            height:72px;
            .texte {display:none;}
        }
        @include brk($m) {
            top: -3px;
            width:250px;
            height:80px;
        }
    }
}


#header .banner-content.hidden {
    position: absolute;
    left:0;
    right:0;
    transition: height 0.6s;
    &.show-promo {
        height: calc(100% - 45px);
    }
    @include brk($t) {
        position: fixed;
        padding:0;
        top:0;
    }
    .hidden-btn-container {
        position: absolute;
        bottom:-100px;
        width:390px;
        right:-30px;
        height:100px;
        overflow: hidden;
        @include brk($t) {
            right: 39px;
            width:390px;
            bottom:-33px;
            height:130px;
        }
        .diagnostic {
            position: absolute;
            right:30px;
            bottom:110px;
            height:72px;
            border-bottom-left-radius:10px !important;
            border-bottom-right-radius:10px !important;
            transition: transform 0.8s;
            // box-shadow: 0px 3px 38px 0px rgba(39, 51, 66, 0.46);
            transform: translate3d(0,0px,0);
            @include brk($t) {
                transform: translate3d(0,100px,0);
                height: $header-height-tablet;
                right:30px;
                margin-right: 0!important;
                bottom:133px;
                border-bottom-right-radius:0px !important;
            }
        }
    }
}





#header .diagnostic, .diagnostic-tablet.tablet-on {
    height: 100%;
    float: right;
    display: inline-block;
    background:#1599d6;
    width:330px;
    padding-top:12px;
    position: relative;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    &.show-promo {
        transform: translateY(68px);
        @include brk($m) {
            transform: translateY(80px);
        }
        &.is-active {
            transition: transform 1s ease 0s;
            transform: translateY(0);
        }
    }
    @include brk($t) {
        position: fixed;
        right:0;
        top:0;
        z-index: 100;
        width:320px;
        padding-top:11px;
        height:$header-height-tablet;
        border-bottom-left-radius:10px !important;
        border-bottom-right-radius:0px;
        margin-right: $header-height-tablet;
    }
    @include brk($m) {
        display: none!important;
    }
    &:hover {
        background:darken($touche_de_couleur2, 10%);
        .icon-arrow span {transform:translate3d(1px,0,0);}
    }
    p {
        padding-left:88px;
        user-select:none;
        color:#fff;
        line-height: 1.5;
        font-size: 16px;
        font-weight: $bold;
        @include brk($t) {padding-left:83px;}
    }
    .icon {
        width:42px;
        height:42px;
        position: absolute;
        top:14px;
        left:22px;
        .st0{clip-path:url(#XMLID_14_);fill:#FFFFFF;}
        .st1{clip-path:url(#XMLID_14_);fill:#40A2C2;}
        @include brk($t) {top:13px;}
    }
    &.page { 

        .icon {
            .st0{clip-path:url(#XMLID_14_);fill:#2da3da;}
            .st1{clip-path:url(#XMLID_14_);fill:#fff;}
        }
    }
    .icon-arrow {
        font-size: 30px;
        color:#fff;
        width:40px;
        text-align: center;
        font-weight: $normal;
        height:48px;
        padding-top:7px;
        position: absolute;
        top:12px;
        line-height: 1;
        right:5px;
        border-left:1px solid rgba(255,255,255,0.3);
        span {
            transition: transform 0.5s;
            display: inline-block;
            text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
        }
    }
}






#header .appel-gratuit {
    width:325px;
    padding-top:12px;
    border: 1px solid rgba(255,255,255,0.5);
    border-top:none!important;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    height: 100%;
    float: right;
    display: inline-block;
    margin-right: 24px;
    position: relative;
    cursor: default;
    @include brk($t) {
        display: none;
    }
    &:hover {
        // background:darken(#1599d6, 25%);
        // border: 1px solid darken(#1599d6, 25%);
    }
    p {
        color: #fff;
        padding-left:88px;
        font-size: 14px;
        font-weight: $normal;
        &.bold {
            font-size: 15px;
            font-weight: $bold;
        }
        span {
            color:#104b99;
        }
    }
    .icon {
        width:42px;
        height:42px;
        position: absolute;
        top:14px;
        left:22px;
        .st0{fill:#FFFFFF;}
    }
    &.page  {
        background:#edf1f4;
        p {color:#494948;}
        .icon { 
            background:#edf1f4; 
            z-index: 2;
            .st0 { fill:#f6f8fa;}
            &:before {
                content:"";
                width:20px;
                height:20px;
                display: inline-block;
                position: absolute;
                top:10px;
                left:10px;
                z-index: -1;
                background:#104b99;
            }
        }
    }
}


#header_bottom {
    height:88px;
    .banner-content .content {
        border-bottom: 1px solid rgba(255,255,255,0.15);
    }
    &.page .content {
        position: relative;
        &:before {
            content:"";
            width:100%;
            position: absolute;
            top:22px;
            left:0;
            border-top: 1px solid rgba(0,0,0,0.08);
        }
    }
    @include brk($t) {display:none;}
}

#nav_desktop {
    display:inline-block;
    height:100%;
    float:right;
    padding-top:16px;
    margin-right: -12px;
    @include brk($menu-off) {display:none;}
    >ul {
        list-style-type:none;
        height:100%;
        user-select: none;
        >li {
            display:inline-block;
            height: 100%;
            margin-left:-5px;
            user-select: none;
            padding:0 0px;
            position: relative;
            // &.selected a, &.current-menu-item a, &.current_page_ancestor a {border-bottom:4px solid white;}
            // &:hover a {border-bottom:4px solid white;cursor:pointer!important}
            &:hover>a {color:$white;}
            a {
                display: inline-block;
                padding: 28px 11.2px 20px;
                font-size: 15px;
                font-weight: $bold;
                color: #fff;
                // text-transform: uppercase;
                height:100%;
                user-select: none;
            }
            &:first-child {
                position:relative; 

                a::after {
                    content:"";
                    width:26px;
                    height: 27px;
                    display: inline-block;
                    position:absolute;
                    top:27px;
                    left:10px;
                    cursor:pointer!important;
                    background:url('../assets/images/icon-home.svg');
                }
                a {color: rgba(0,0,0,0);width:46px;}
            }
        }
    }
    &.page {
        >ul>li>a {
            color:#585b60;
        }
        >ul>li {
            &:first-child {
                a::after {
                    background:url('../assets/images/icon-home-2.svg');
                }
                a {color:#fff; width:46px;}
            }
        }
    }




    // SOULIGNÉ ----------------------- 

    >ul>li:not(.btn)>a:before {
        content:"";
        width:0px;
        opacity: 0;
        transition: width 0.2s, opacity 0.5s, background 0.3s;
        height:5px;
        position: absolute;
        display: inline-block;
        background:#3ea2c2;
        left:11px;
        bottom:-1px;
    }
    >ul>li:not(.btn):hover >a:before,
    >ul>li:not(.btn).current-menu-ancestor >a:before,
    >ul>li:not(.btn).current-menu-item >a:before {
        opacity: 1;
        width:calc(100% - 20px);
    }
    &.page>ul>li:not(.btn)>a:before {
        background:#1599d6;
    }



    // FLECHE PARENT -----------------

    >ul>li.menu-item-has-children {
        position: relative;
        >a {padding-right:30px;}
        >a::after {
            content:"";
            width:7px;
            height:8px;
            display: inline-block;
            position:absolute;
            top:50%;
            right:16px;
            cursor:pointer!important;
            transform: translateY(1px);
            background:url('../assets/images/parent-arrow-white.svg');
        }
    }
    &.page >ul>li.menu-item-has-children {
        >a::after {
            background:url('../assets/images/parent-arrow.svg');
        }
    }

    // SEPARATION VERTICALE -----------------

    >ul>li:before {
        content:"";
        width:1px;
        height:15px;
        position: absolute;
        display: inline-block;
        background:#fff;
        opacity: 0.2;
        top:32px;
        left:0px;
    }
    >ul>li:first-child:before {display:none;}
    >ul>li:nth-child(2):before {display:none;}

    &.page>ul>li:before {
        background:#1599d6;
        opacity: 0.5;
    }

    >ul>li>ul {
        // display: none;
    }
}









//  LEVEL 2   ------------------------------------


#nav_desktop >ul>li {
    &:hover ul {
        display:block;
    }
    >ul {
        position:absolute;
        left:0px;
        top:67px;
        left:0%;
        left: 33px;
        // transform: translateX(-50%);
        margin-left: -22px;
        margin-top: 0px;
        display: none;
        z-index: 10;
        // padding: 0 60px 60px 40px;
        box-shadow: 2px 4px 14px 0px transparentize(#000, 0.85);
    }
    >ul>li {
        background:#fff;
        display:inline-block;
        float:left;
        line-height: 1;
        border-top: 1px solid transparentize(#000, 0.9);
        transition: border 1s;
        box-shadow: 2px 4px 14px 0px transparentize($touche1, 0.91);
        &:first-child {
            border:none!important;
            a {
                border-top: 5px solid transparentize($touche1, 0)!important;
            }
        }
        &.current-menu-item, &:hover {
            background:lighten(#000, 95%);
            &, & + li {
                transition: border 0.5s;
                border-top: 1px solid transparentize(#000, 1);
            }
            a {
                // color:$touche2;
            }
        }
        &.current-menu-item>a {
            color:$touche2;
            font-weight: $bold;
            &::after {display:none}
        }
        >a {
            line-height: 1;
            font-size:15px;
            font-weight: $bold;
            color: $touche1;
            width:330px;
            padding: 26px 30px;
            // text-transform: uppercase;
            letter-spacing: 0.2px;
            position: relative;
            &::after {
                content:"›";                
                font-weight: $normal;
                font-size: 23px;
                position: absolute;
                display: inline-block;
                transition: transform 0.15s;
                top:20px;
                right:25px;
                opacity: 0;
            }
            &:hover {
                &::after {
                    opacity: 1;
                    transform: translate3d(1px,0,0);
                }
            }
        }
    }
    // &.current_page_ancestor>ul {display:inline-block;}
    >ul>li.menu-item-has-children.current-menu-item ul{display:block}
    >ul>li>ul {
        display: block;
        position:absolute;
        top:100px;
        left:0px;
        height:100px;
        display:none!important;
        background:$level3; width:40000px;
        >li {
            display:inline-block;
            float:left;
            height:100%;
            &:first-child a {padding-left:0}
            a {
                padding-top:42px;
                display:inline-block;
                font-size:13px;
                font-weight: 700;
                color:#333333;
                &:hover {color:$dark-grey;}
            }
        }
    }
}











