



$page_wrapper-bannerTop-desktop: 581px;
$page_wrapper-bannerTop-laptop: 580px;
$page_wrapper-bannerTop-tablet: 580px;
$page_wrapper-bannerTop-mobile: 580px;




.page.single.aide .banner.top {


	margin-bottom: 0px;
	height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { 
        height:$page_wrapper-bannerTop-tablet; 
        margin-top: $header-height-tablet;
    }
    @include brk($m) { 
        height:$page_wrapper-bannerTop-mobile; 
        margin-top: $header-height-mobile;
    }

    .image {
    	background-position: 50% 0%!important;
    	@include brk($t) {
            background-position: 0% 0%!important;
        }
    }

	.icon-garantie {
		width:100px;
		height: 100px;
		position: absolute;
		right:0;
		bottom:150px;
		animation: fade 2s;
		.st0{fill:none;stroke:#fff;stroke-width:0.2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
		.st1{fill:#fff;}
		.st2{fill:#fff;}
		.st3{font-family:'Montserrat';font-weight:$bold;}
		.st4{font-size:1.9344px;}
		.st5{font-family:'Montserrat';font-weight:$light;}
		.st6{fill:none;stroke:#fff;stroke-width:0.173;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
		.st7{fill:none;stroke:#fff;stroke-width:0.173;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
		.st8{fill:none;stroke:#fff;stroke-width:0.173;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
		@include brk($t) { bottom:130px; }
		@include brk($m) {display:none}
	}

	.textes-container {
		animation: fade 2s;
		width:100%;
		position: absolute;
		bottom:0;
		left:0;
		padding: 30px 0 65px;
		.titre {
	        animation: fade 2s;
	        font-size:50px;
	        line-height: 1.1;
	        font-weight: $thin;
	        letter-spacing: 0.5px;
	        color:#fff;
	        position: relative;
	        z-index: 4;
	        margin-bottom: 165px;
	        @include brk($t) { margin-bottom: 45px; }
	        @include brk($m) { font-size: 25px; }
	    }
		.bottom {
			width:100%;
			padding: 30px 0 62px;
			border-top:1px solid transparentize(#fff, 0.8);
			@include brk($t){
				padding: 30px 0 55px;
			}
			.sous-titre {
				width:40%;
				float: left;
				@include brk($t){
					width:100%;
					margin-bottom: 12px;
					br {display:none;}
				}
				p {
					color:#fff;
					font-size: 27px;
					font-weight: $bold;
				}
			}
			ul {
				width:50%;
				float: right;
				@include brk($t){
					width:100%;
					br {display:none;}
				}
				li {
					position: relative;
					&:before {
						content:"›";
						position: absolute;
						left:-10px;
						top:3px;
						color:$touche_de_couleur;
					}
					p {
						color:$touche_de_couleur;
						line-height: 2;
						font-size: 17px;
						font-weight: $light;
					}
				}
			}
		}

	}
}




.page.single.aide {
	.flex-texte_image, .flex-image_texte {
		.col-image .image {
			background-size:contain !important;
		}
	}
	.flex-intro {
		margin-bottom:80px;
		@include brk($m) {
			margin-bottom:50px;
		}
	}
	.flex-focus_fond_bleu {
		margin-bottom: 0 !important;
	}

}


